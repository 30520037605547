import React, { Component } from 'react';
import { fabric } from 'fabric';
import { SketchPicker } from 'react-color';
import FontPicker from 'font-picker-react';
import Popup from 'reactjs-popup'
import { getOffset, saveCanvasState, selectObject, removeNumbering } from './Helpers'
import { DEFAULT_FONT_PROPS } from "../constants";
import { Container, Row } from "reactstrap";
import $ from 'jquery';
//import { text } from '@fortawesome/fontawesome-svg-core';
import config from '../../config';
import axios from 'axios';
var FontFaceObserver = require('fontfaceobserver');

class Toolbar extends Component {
    state = {
        textColor: '',
        glowColor: '',
        strokeColor: '',
        value: '6',
        opacityval: '1',
        strokeval: '1',
        blurval: '1',
        offsetX: '1',
        offsetY: '1',

        activeFontFamily: "Montserrat",
        savestateaction: true,
        displayColorPicker: false,
        displaystrokeColorPicker: false,
        displayglowColorPicker: false,
        collapse: false,
        // activeObjectWidth:200,
        activeObjectHeight: 200,
        glowcollapse: false,
        open: false,
        backgroundcol: '',
        glowbackgroundcol: '',
        outlinechecked: false,
        glowchecked: false,
        txtlock: false,
        styles: {
            position: 'absolute',
            display: 'none',
        },
    };

    constructor(props) {
        super(props);
        this.textcolorRef = React.createRef();
        this.pickerRef = React.createRef();
        this.textstrokecolRef = React.createRef();
        this.textglowcolRef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            activeObjectWidth: 200,
            activeObjectHeight: 200,
        })

        $(".strokeeff").hide();
        $(".iconbar").hide();
    }

    componentWillReceiveProps = (newprops) => {
        var canvas = this.props.state.canvas;
        if (canvas) {
            var activeObject = canvas.getActiveObject();

            var left = getOffset('front').left;
            var top = getOffset('front').top;
            if (activeObject) {
                this.setState({
                    styles: {
                        top: activeObject.top + top - 50,
                        left: activeObject.left + left + activeObject.width * activeObject.scaleX / 2 + 10,
                        position: 'fixed',
                        display: 'block',
                        zIndex: 1000
                    }
                })
            } else {
                this.setState({
                    styles: {
                        display: 'none',
                    }
                })
            }
        }
        this.selobject();
    }

    selobject = () => {
        var canvas = this.props.state.canvas;
        if (canvas) {
            var activeObject = canvas.getActiveObject();
            if (!activeObject) return false;
            if (activeObject.type === 'text') {

                this.setState({
                    value: activeObject.fontSize,
                    activeFontFamily: activeObject.fontFamily,
                    opacityval: Math.round(activeObject.opacity * 100)
                });


                if (activeObject.shadow) {
                    this.setState({
                        offsetX: activeObject.shadow.offsetX,
                        blurval: activeObject.shadow.blur,
                        offsetY: activeObject.shadow.offsetY,
                        glowColor: activeObject.shadow.color,
                        glowbackgroundcol: activeObject.shadow.color,
                        glowcollapse: true,
                        glowchecked: true
                    });

                } else {

                    this.setState({
                        offsetX: 1,
                        blurval: 1,
                        offsetY: 1,
                        glowColor: '',
                        glowcollapse: false,
                        glowbackgroundcol: '',
                        glowchecked: false
                    });
                }

                if (activeObject.strokeWidth && activeObject.stroke) {
                    this.setState({
                        strokeColor: activeObject.stroke,
                        backgroundcol: activeObject.stroke,
                        strokeval: activeObject.strokeWidth,
                        collapse: true,
                        outlinechecked: true
                    });
                } else {

                    this.setState({
                        strokeColor: '',
                        backgroundcol: '',
                        strokeval: 1,
                        collapse: false,
                        outlinechecked: false
                    });

                }
                //It was changing color of picker arrow
                //$(".textcolpick").css("background", activeObject.fill);

                if (activeObject.fontWeight === 'bold') {
                    $(".tbold").css("opacity", "1");
                } else {
                    $(".tbold").css("opacity", "0.5");
                }
                if (activeObject.fontStyle === 'italic') {
                    $(".titalic").css("opacity", "1");
                } else {
                    $(".titalic").css("opacity", "0.5");
                }
                if (activeObject.underline === 'underline') {
                    $(".tunder").css("opacity", "1");
                } else {
                    $(".tunder").css("opacity", "0.5");
                }
                if (activeObject.textAlign === 'left') {
                    $(".tleft").css("opacity", "1");
                } else {
                    $(".tleft").css("opacity", "0.5");
                }
                if (activeObject.textAlign === 'center') {
                    $(".tcenter").css("opacity", "1");
                } else {
                    $(".tcenter").css("opacity", "0.5");
                }
                if (activeObject.textAlign === 'right') {
                    $(".tright").css("opacity", "1");
                } else {
                    $(".tright").css("opacity", "0.5");
                }
            }

            if (activeObject.type === 'path') {
                this.setState({
                    strokeval: activeObject.strokeWidth,
                    opacityval: Math.round(activeObject.opacity * 100)
                });
            }
            if (activeObject.type === 'image') {
                this.setState({
                    opacityval: Math.round(activeObject.opacity * 100)
                });
            }

            if (activeObject.type === 'group') {
                this.setState({
                    strokeval: activeObject.strokeWidth,
                    opacityval: Math.round(activeObject.opacity * 100)
                });
            }
        }
    }

    setStyle = (styleName, value, o) => {
        if (o.setSelectionStyles && o.isEditing) {
            var style = {};
            style[styleName] = value;
            o.setSelectionStyles(style);
        } else {
            o.set(styleName, value);
        }
        o.setCoords();
    }

    setActiveStyle(styleName, value, object) {
        var canvas = this.props.state.canvas;
        object = object || canvas.getActiveObject();

        if (!object) return;
        if (object.setSelectionStyles && object.isEditing) {
            var style = {};
            style[styleName] = value;
            object.setSelectionStyles(style);
            object.setCoords();
        } else {
            console.log(styleName, value);
            object.set(styleName, value);
        }
        object.setCoords();
        canvas.renderAll();
    }

    setTextFont = (fontfamily) => {
        var self = this;
        var myfont = new FontFaceObserver(fontfamily);
        myfont.load().then(function () {
            self.setActiveStyle('fontFamily', fontfamily);
        }).catch(function (e) {
            console.log(e);
        });
        this.setState({
            activeFontFamily: fontfamily
        })
    }

    setTextBold = () => {
        var fontBoldValue = (this.props.state.fontBoldValue === "normal") ? "bold" : "normal";
        this.setActiveStyle('fontWeight', fontBoldValue);
        this.props.state.fontBoldValue = fontBoldValue;
    }

    setTextItalic = () => {
        var fontItalicValue = (this.props.state.fontItalicValue === "normal") ? "italic" : "normal";
        this.setActiveStyle('fontStyle', fontItalicValue);
        this.props.state.fontItalicValue = fontItalicValue;
    }

    setTextUnderline = () => {
        var fontUnderlineValue = !this.props.state.fontUnderlineValue ? "underline" : false;
        this.setActiveStyle('underline', fontUnderlineValue);
        this.props.state.fontUnderlineValue = fontUnderlineValue;
    }
    setTextStrike = () => {
        var fontStrikeValue = !this.props.state.fontStrikeValue ? "linethrough" : false;
        this.setActiveStyle('linethrough', fontStrikeValue);
        this.props.state.fontStrikeValue = fontStrikeValue;
    }

    setBulletText = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        const obj = canvas.getActiveObject();
        const fontWeight = obj?.fontWeight ? obj.fontWeight : 'normal';
        const fontStyle = obj?.fontStyle ? obj.fontStyle : '';
        const underline = obj?.underline ? obj.underline : '';


        const textProperties = {
            id: Math.floor(Math.random() * 1000),
            fontFamily: DEFAULT_FONT_PROPS.font,
            left: activeObject.left,
            top: activeObject.top,
            fontSize: activeObject.fontSize,
            fontWeight: fontWeight,
            fontStyle: fontStyle,
            underline: underline,
            type: 'text',
            strokeWidth: activeObject.strokeWidth,
            strokeLineJoin: 'bevil',
            fill: activeObject?.fill,
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: '#1babeb',
            cornerStrokeColor: '#1babeb',
            borderColor: '#1babeb',
            cornerSize: 5,
            padding: 5,
            cornerStyle: 'circle'
        }
        if (obj.text?.indexOf('●') !== -1) {
            const updateText = obj.text.split('●').join('')
           // obj['text'] = updateText
            //obj.listStyle = ""
            var textp = new fabric.IText(updateText, textProperties);
            activeObject.text = "";
            activeObject.listStyle = ""
            canvas.add(textp);
            canvas.setActiveObject(textp);
            canvas.renderAll();
        } else {
            var styles=['\u25CF','\u25C8','\u25D8','\u25BA','\u25CB','\u25A0','-'];
            var allStyles={'bullet':'\u25CF','diamond':'\u25C8','invertedBullet':'\u25D8','triangularBullet':'\u25BA','disced':'\u25CB','squared':'\u25A0','dashed':'-','none':''};
            const text = obj.text
            const textArray = typeof text === 'string' ? text?.split('\n') : []
            const tempStr = []
            const type = 'bullet';
            textArray.forEach((text, i) => {
                let listStyle = activeObject.listStyle
                let textLine = text
                if(listStyle === 'ordered') {
                    textLine = removeNumbering(textLine)
                }
                if(styles.includes(textLine.charAt(0))) {
                    tempStr.push(textLine.replace(textLine.charAt(0),allStyles[type]));
                } else {
                    tempStr.push(allStyles[type]+''+textLine);
                }
            })

            //obj['listStyle'] = 'unordered';
            //obj['text'] = tempStr.join('\n');
            //canvas.add(obj);
            //canvas.renderAll();

            var texto = new fabric.IText(tempStr.join('\n'), textProperties);
            activeObject.text = "";
            activeObject.listStyle = "unordered"
            canvas.add(texto);
            canvas.setActiveObject(texto);
            selectObject(canvas);
            canvas.renderAll();
        }

    }

    setNumberText = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        const obj = canvas.getActiveObject();
        const fontWeight = obj?.fontWeight ? obj.fontWeight : 'normal';
        const fontStyle = obj?.fontStyle ? obj.fontStyle : '';
        const underline = obj?.underline ? obj.underline : '';
        if (obj.listStyle === 'ordered' && obj.text) {
            const tmpStrPoints = typeof obj.text == 'string' ? obj.text?.split('\n') : []
            const tmpStrPointArr = []
            if(tmpStrPoints) {
                tmpStrPoints.forEach(textPoint => {
                    textPoint = removeNumbering(textPoint)
                    tmpStrPointArr.push(textPoint)
                })
                const updateText = tmpStrPointArr.join('\n')
                var text = new fabric.IText(updateText, {
                    id: Math.floor(Math.random() * 1000),
                    fontFamily: DEFAULT_FONT_PROPS.font,
                    left: activeObject.left,
                    top: activeObject.top,
                    fontSize: activeObject.fontSize,
                    fontWeight: fontWeight,
                    fontStyle: fontStyle,
                    underline: underline,
                    type: 'text',
                    strokeWidth: activeObject.strokeWidth,
                    strokeLineJoin: 'bevil',
                    fill: activeObject?.fill,
                    subTargetCheck: true,
                    transparentCorners: false,
                    cornerColor: '#1babeb',
                    cornerStrokeColor: '#1babeb',
                    borderColor: '#1babeb',
                    cornerSize: 5,
                    padding: 5,
                    cornerStyle: 'circle',
                    listStyle:'',
                });
                obj.text = ''
                obj.listStyle = ""
                canvas.add(text);
                canvas.setActiveObject(text);
                selectObject(canvas);
                canvas.renderAll();
            }


            canvas.renderAll();
        } else {
            //var canvas = this.props.state.canvas;
            //var activeObject = canvas.getActiveObject();
            var bulletPoint = '●'; //\u2022
            let txt = activeObject.text

            let numTxt;
            txt = txt.split("\n")
            var i = 1;
            var x = [];
            txt.forEach(element => {
                let pat = /[0-9]\./
                if (pat.test(element)) {
                    element = element.replace(pat, "")
                }
                if (element.includes(bulletPoint)){
                    element = element.replace(bulletPoint, "")
                }
                //if (element != '') {
                    if(i < txt.length){
                        x[i] = i + "." + element + "\n"
                    } else {
                        x[i] = i + "." + element
                    }
                    numTxt = x.toString()
                    numTxt = numTxt.replace(",", "")
                    numTxt = numTxt.replace(bulletPoint, "")
                    i++
                //}
            });
            var text = new fabric.IText(numTxt.replace(/,/g, ''), {
                id: Math.floor(Math.random() * 1000),
                fontFamily: DEFAULT_FONT_PROPS.font,
                left: activeObject.left,
                top: activeObject.top,
                fontSize: activeObject.fontSize,
                fontWeight: fontWeight,
                fontStyle: fontStyle,
                underline: underline,
                type: 'text',
                strokeWidth: activeObject.strokeWidth,
                strokeLineJoin: 'bevil',
                fill: activeObject?.fill,
                subTargetCheck: true,
                transparentCorners: false,
                cornerColor: '#1babeb',
                cornerStrokeColor: '#1babeb',
                borderColor: '#1babeb',
                cornerSize: 5,
                padding: 5,
                cornerStyle: 'circle',
                listStyle:'ordered',
            });
            activeObject.text = "";
            activeObject.listStyle = "ordered"
            canvas.add(text);
            canvas.setActiveObject(text);
            selectObject(canvas);
            canvas.renderAll();
        }
    }

    setActiveProp = (name, value) => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (!activeObject) return;
        if (activeObject.type === 'activeSelection') {
            activeObject.forEachObject(function (object) {
                object.set(name, value).setCoords();
            });
        } else if (activeObject) {
            activeObject.set(name, value).setCoords();
        }
        canvas.renderAll();
        saveCanvasState(canvas);
    }

    alignObjectLeft = (value) => {
        this.setActiveProp('textAlign', 'left');
    }

    alignObjectCenter = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        activeObject.set({
            left: (canvas.width / 2) - ((activeObject.width * activeObject.scaleX) / 2)
        });
        activeObject.setCoords();
        this.setActiveProp('textAlign', 'center');
    }

    alignObjectRight = () => {
        this.setActiveProp('textAlign', 'right');
    }

    clearCanvas = () => {

        var canvas = this.props.state.canvas;
        canvas.clear();
    }

    // deleteItem = () => {
    //     var canvas = this.props.state.canvas;
    //      let apiDynamicUrlAddress = config.default.api.host;
    //     var activeObject = canvas.getActiveObject();
    //     var id=activeObject.objId
    //     if (activeObject.type === 'activeSelection') {
    //         activeObject.forEachObject(function (object) {
    //             canvas.remove(object);
    //         });
    //     } else {
    //         canvas.remove(activeObject);

    //     let apiUrl = `${apiDynamicUrlAddress}/deleteItem?id=${id}`
    //     axios.get(apiUrl, {
    //         crossDomain: true,
    //         enablePreflight: false
    //     }).then((response) => {
    //         window.location.reload();

    //     })
    //         .catch((err) => {

    //         });
    //     }
    // }
    deleteItem = () => {
        var canvas = this.props.state.canvas;
        var apiDynamicUrlAddress = config.default.api.host;
        var activeObject = canvas.getActiveObject();
        var id = activeObject.objId
        if (!activeObject) return;
        if (activeObject.type === 'activeSelection') {
            activeObject.forEachObject(function (object) {
                canvas.remove(object);
            });
        } else {
            if (id === undefined)
                canvas.remove(activeObject);
            else {
                let apiUrl = `${apiDynamicUrlAddress}/deleteItem?id=${id}`
                axios.get(apiUrl, {
                    crossDomain: true,
                    enablePreflight: false
                }).then((response) => {
                    if (response.data.success === 1) {
                        window.location.reload();
                    }
                    //canvas.remove(activeObject);
                    //window.location.reload();

                })
                    .catch((err) => {

                    });
            }

        }
    }

    setColor = (color) => {
      
        var canvas = this.props.state.canvas;
        if (canvas._activeObject.type === 'text') {
            canvas._activeObject.set('fill', color.hex)
            canvas.renderAll();
        }
        this.changeObjectColor(color.hex);
        this.setState({ textColor: color.hex });
        if(this.textcolorRef.current)
        this.textcolorRef.current.style.background = color.hex;
        this.selobject();
    
    };

    pickerOpen = () => {
        this.setState({
            displayColorPicker: !this.state.displayColorPicker
        })
    };

    rotate = () => {
        var canvas = this.props.state.canvas;

        if (canvas._activeObject) {
            var curAngle = canvas._activeObject.angle;
            canvas._activeObject.rotate(curAngle + 90);
            canvas.renderAll();
        }
    };
    componentDidUpdate(){
        //console.log('re-ren')
    }
    lock = () => {
        this.setState({
            lock: true
        })
        var canvas = this.props.state.canvas;

        if (canvas._activeObject) {
            canvas._activeObject.isLocked = true
            canvas._activeObject.lockMovementX = true;
            canvas._activeObject.lockMovementY = true;
            canvas._activeObject.lockScalingX = true;
            canvas._activeObject.lockScalingY = true;
            canvas._activeObject.lockRotation = true;
            canvas.renderAll();
        }
    };
    unlock = () => {
        this.setState({
            lock: false
        })
        var canvas = this.props.state.canvas;
        if (canvas._activeObject) {
            canvas._activeObject.isLocked = false
            canvas._activeObject.lockMovementX = false;
            canvas._activeObject.lockMovementY = false;
            canvas._activeObject.lockScalingX = false;
            canvas._activeObject.lockScalingY = false;
            canvas._activeObject.lockRotation = false;
            canvas.renderAll();
        }
    };
    pickerClose = () => {
        this.setState({
            displayColorPicker: false
        })
    };

    strokepickerOpen = () => {
        this.setState({
            displaystrokeColorPicker: !this.state.displaystrokeColorPicker
        })
    };

    strokepickerClose = () => {
        this.setState({
            displaystrokeColorPicker: false
        })
    };

    glowpickerOpen = () => {
        this.setState({
            displayglowColorPicker: !this.state.displayglowColorPicker
        })
    };

    glowpickerClose = () => {
        this.setState({
            displayglowColorPicker: false
        })
    };

    setStroke = (color) => {
        this.changeObjectproperty('stroke', color.hex);
        this.setState({
            strokeColor: color.hex
        });
        this.textstrokecolRef.current.style.background = color.hex;
    };

    changeObjectColor = (hex) => {
        this.changeObjectproperty('fill', hex);
    }

    changeObjectproperty(style, hex) {
        let lthis = this;
        var canvas = this.props.state.canvas;
        let obj = canvas.selectedObject;

        if (!obj)
            obj = canvas.getActiveObject();

        if (obj) {
            if (obj.paths) {
                for (let i = 0; i < obj.paths.length; i++) {
                    this.setActiveStyle(style, hex, obj.paths[i]);
                }
            } else if (obj.type === "group") {
                let objects = obj.getObjects();
                for (let i = 0; i < objects.length; i++) {
                    this.setActiveStyle(style, hex, objects[i]);
                }
            }
            else this.setActiveStyle(style, hex, obj);
        } else {
            let grpobjs = canvas.getActiveObjects();
            if (grpobjs) {
                grpobjs.forEach(function (object) {
                    if (object.paths) {
                        for (let i = 0; i < object.paths.length; i++) {
                            lthis.setActiveStyle(style, hex, obj.paths[i]);
                        }
                    }
                    else lthis.setActiveStyle(style, hex, obj);
                });
            }
        }
        canvas.renderAll();
        saveCanvasState(canvas);
    }

    fontSize = (event) => {
        this.setState({
            value: event.target.value
        });
        this.setActiveStyle('fontSize', event.target.value);
    }

    clone = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (!activeObject) return false;
        if (activeObject.type === 'activeSelection') {
            activeObject.forEachObject((object) => {
                this.cloneSelObject(object);
            });
        }
        else {
            this.cloneSelObject(activeObject);
        }
    }

    cloneSelObject = (actobj) => {
        var canvas = this.props.state.canvas;
        canvas.discardActiveObject();
        if (fabric.util.getKlass(actobj.type).async) {
            var clone = fabric.util.object.clone(actobj);
            clone.set({
                left: actobj.left + 50,
                top: actobj.top + 50
            });
            canvas.add(clone);
            saveCanvasState(canvas);
        } else {
            var clones = fabric.util.object.clone(actobj);
            canvas.add(clones.set({
                left: actobj.left + 50,
                top: actobj.top + 50
            }));
            saveCanvasState(canvas);
        }
        canvas.requestRenderAll();
    }

    setOpacity = (event) => {
        this.setState({
            opacityval: event.target.value
        });
        this.setActiveStyle('opacity', event.target.value / 100);
    }

    setStrokeval = (event) => {
        console.log(event.target.value);
        this.setState({
            strokeval: event.target.value
        });
        this.changeObjectproperty('strokeWidth', event.target.value * 1);
    }

    outlinetoggle = () => {
        this.setState({
            collapse: !this.state.collapse
        })
        if (this.state.collapse === true) {
            this.changeObjectproperty('strokeWidth', null);
            this.changeObjectproperty('stroke', null);
        } else {
            this.changeObjectproperty('strokeWidth', this.state.strokeval);
            this.changeObjectproperty('stroke', this.state.strokeColor);
        }
    }

    setGlow = (color) => {
        this.textglowcolRef.current.style.background = color.hex;
        this.setState({
            glowColor: color.hex
        });
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
            activeObject.setShadow({
                color: color.hex,
                blur: 1,
                offsetX: 1,
                offsetY: 1
            });
        }
        canvas.renderAll();
    }
    shapeUpload = (event) => {
        var canvas = this.props.state.canvas;
        var reader = new FileReader();
        reader.onload = function (event) {
            var image = new Image();
            image.src = event.target.result;
            image.onload = function () {
                var img = new fabric.Image(image);
                img.set({
                    left: 100,
                    top: 100
                });
                var activeObject = canvas.getActiveObject();
                activeObject.set('fill', new fabric.Pattern({
                    source: image,
                    repeat: 'repeat'
                }));
                canvas.renderAll();
            }
        }
        reader.readAsDataURL(event.target.files[0]);

    }
    setglowblur = (event) => {
        this.setState({
            blurval: event.target.value
        });
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
            activeObject.setShadow({
                blur: event.target.value,
                color: this.state.glowColor,
                offsetX: this.state.offsetX,
                offsetY: this.state.offsetY,
            });
        }
        canvas.renderAll();
    }

    setglowoffsetX = (event) => {
        this.setState({
            offsetX: event.target.value
        });
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
            activeObject.setShadow({
                blur: this.state.blurval,
                color: this.state.glowColor,
                offsetX: event.target.value,
                offsetY: this.state.offsetY,
            });
        }
        canvas.renderAll();
    }

    setglowoffsetY = (event) => {
        this.setState({
            offsetY: event.target.value
        });
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (activeObject) {
            activeObject.setShadow({
                blur: this.state.blurval,
                color: this.state.glowColor,
                offsetX: this.state.offsetX,
                offsetY: event.target.value
            });
        }
        canvas.renderAll();
    }

    glowtoggle = () => {
        this.setState({
            glowcollapse: !this.state.glowcollapse
        })
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        if (this.state.glowcollapse === true) {
            if (activeObject) {
                activeObject.setShadow({
                    color: '',
                    blur: 0,
                    offsetX: 0,
                    offsetY: 0
                });
            }
            canvas.renderAll();
        } else {
            if (activeObject) {
                activeObject.setShadow({
                    color: this.state.glowColor,
                    blur: this.state.blurval,
                    offsetX: this.state.offsetX,
                    offsetY: this.state.offsetY
                });
            }
            canvas.renderAll();
        }
    }

    bringForward = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        var grpobjs = canvas.getActiveObjects();
        if (grpobjs) {
            grpobjs.forEach((object) => {
                if(object?.isLocked !== true){
                    canvas.bringForward(object);
                }
                canvas.renderAll();
                saveCanvasState(canvas);
            });
        } else {

            if(activeObject?.isLocked !== true){
                canvas.bringForward(activeObject);
            }
            canvas.renderAll();
            saveCanvasState(canvas);

        }
    }

    sendBackward = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        var grpobjs = canvas.getActiveObjects();
        if (grpobjs) {
            grpobjs.forEach((object) => {
                if(object?.isLocked !== true){
                    canvas.sendBackwards(object);
                }

                canvas.renderAll();
                saveCanvasState(canvas);
            });
        } else {
            if(activeObject?.isLocked !== false){
                canvas.sendBackwards(activeObject);
            }
            canvas.renderAll();
            saveCanvasState(canvas);
        }
    }
    setWidth = () => {
        var canvas = this.props.state.canvas;
        $(".width").click(function () {
            //var widdth = $(this).val()



            //var activeObject = canvas.getActiveObject();

            var grpobjs = canvas.getActiveObjects();

            if (grpobjs) {
                grpobjs.forEach((object) => {

                    if (object.width) {
                        object.width = object.width + 5;
                        object.radius = object.radius + 5;
                        // this.setState({
                        //     activeObjectHeight:object.width
                        // })
                    }
                    else {
                        object.width = object.width + 5;
                        object.radius = object.radius + 5;
                    }
                    canvas.renderAll();
                    saveCanvasState(canvas);
                });
            }
            // else {
            //     if (activeObject.width) activeObject.width = object.width+10;
            //     else activeObject.width = object.width+10;
            //     canvas.renderAll();
            //     saveCanvasState(canvas);

            // }
        });
    }
    setHeight = () => {
        // $(".width").click(function () {
        // var canvas = this.props.state.canvas;
        // var activeObject = canvas.getActiveObject();

        // var grpobjs = canvas.getActiveObjects();

        // if (grpobjs) {
        //     grpobjs.forEach((object) => {

        //         if (object.height)
        //         {object.height = object.height+5;
        //             object.radius = object.radius+5;
        //             this.setState({
        //                 activeObjectHeight:object.height
        //             })
        //         }
        //         else {
        //             object.height = object.height+5;
        //             object.radius = object.radius+5;
        //         }
        //         canvas.renderAll();
        //         saveCanvasState(canvas);
        //     });
        // }
        // // else {
        // //     if (activeObject.width) activeObject.width = object.width+10;
        // //     else activeObject.width = object.width+10;
        // //     canvas.renderAll();
        // //     saveCanvasState(canvas);

        // // }
        // });
    }
    horizontalflip = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        var grpobjs = canvas.getActiveObjects();
        if (grpobjs) {
            grpobjs.forEach((object) => {
                if (object.flipX) object.flipX = false;
                else object.flipX = true;
                canvas.renderAll();
                saveCanvasState(canvas);
            });
        } else {
            if (activeObject.flipX) activeObject.flipX = false;
            else activeObject.flipX = true;
            canvas.renderAll();
            saveCanvasState(canvas);

        }
    }
    verticalflip = () => {
        var canvas = this.props.state.canvas;
        var activeObject = canvas.getActiveObject();
        var grpobjs = canvas.getActiveObjects();
        if (grpobjs) {
            grpobjs.forEach((object) => {
                if (object.flipY) object.flipY = false;
                else object.flipY = true;
                canvas.renderAll();
                saveCanvasState(canvas);
            });
        } else {
            if (activeObject.flipY) activeObject.flipY = false;
            else activeObject.flipY = true;
            canvas.renderAll();
            saveCanvasState(canvas);

        }
    }

    groupItems = () => {
        var canvas = this.props.state.canvas;
        if (!canvas.getActiveObject()) {
            return;
        }
        if (canvas.getActiveObject().type !== 'activeSelection') {
            return;
        }
        canvas.getActiveObject().toGroup();
        selectObject(canvas);
        canvas.renderAll();
    }

    unGroupItems = () => {
        var canvas = this.props.state.canvas;
        if (!canvas.getActiveObject()) {
            return;
        }
        if (canvas.getActiveObject().type !== 'group') {
            return;
        }
        canvas.getActiveObject().toActiveSelection();
        selectObject(canvas);
        canvas.renderAll();
    }

    popupClose = () => {
        this.setState({
            glowcollapse: false,
            open: false,
            collapse: false,
            outlinechecked: false,
            glowchecked: false
        })
    }

    popupOpen = () => {

        this.setState({
            open: true
        });
    }

    outlineCheckBox = (e) => {
        this.setState({
            outlinechecked: e.target.checked
        })
    }

    glowCheckBox = (e) => {
        this.setState({
            glowchecked: e.target.checked
        })
    }


    render() {
        const popover = {
            position: 'absolute',
            zIndex: '2',
            top: '40px',
            left: '282px',
        }
        const popover2 = {
            position: 'absolute',
            zIndex: '2',
            top: '40px',
           
        }
        

        const cover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        const strokepopover = {
            position: 'absolute',
            zIndex: '2',
            top: '150px',
            left: '140px',
        }
        const strokecover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }
        /*const glowpopover = {
            position: 'absolute',
            zIndex: '2',
            top: '150px',
            left: '140px',
        }
        const glowcover = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }*/

        const { canvas } = this.props.state;
        let isObjectLocked = false
        //const { activeObjectWidth, activeObjectHeight } = this.state;
        if (canvas) {
            const activeObject = canvas.getActiveObject();

            if (!activeObject) {
                return null;
            } else {
                isObjectLocked = activeObject?.isLocked ? activeObject.isLocked: false
            }
        } else {
            return null;
        }

        return (
            <div>
            {($(window).width()>1100)?
                <Container className="toolbar-container" style={{minHeight:"77px"}}>

                <div className="leftbar">
                    <div title="Font Family" className="fontFamily">
                        <FontPicker
                            ref={this.pickerRef}
                            apiKey="AIzaSyCOyeDUsAnL-jnWudXBKNNma9cXmXsT4tM"
                            activeFontFamily={this.state.activeFontFamily}
                            limit="150"
                            onChange={nextFont => this.setTextFont(nextFont.family)}
                        />
                    </div>

                    <div className="select-container  fontsize" title="Font Size">
                        &nbsp;&nbsp;<select onChange={this.fontSize} value={this.state.value} style={{ border: '2px solid #ECECEC' }}>
                            <option>6</option>
                            <option>8</option>
                            <option>10</option>
                            <option>12</option>
                            <option>14</option>
                            <option>16</option>
                            <option>18</option>
                            <option>21</option>
                            <option>24</option>
                            <option>28</option>
                            <option>32</option>
                            <option>36</option>
                            <option>42</option>
                            <option>48</option>
                            <option>56</option>
                            <option>64</option>
                            <option>72</option>
                            <option>80</option>
                            <option>88</option>
                            <option>96</option>
                            <option>104</option>
                            <option>120</option>
                            <option>144</option>
                        </select>

                    </div>

                    <div className="font-color-container colorpic" onClick={this.pickerOpen} title="Font/Fill Color">
                        <div className="colrsec" onClick={this.pickerOpen}>
                            <div ref={this.textcolorRef} className="primcol textcolpick" />
                            &nbsp;&nbsp;<img className="arrowimg  textcolpick" src={require('../images/down-arrow.png')} alt="" onClick={this.pickerOpen} />
                        </div>
                    </div>

                    {this.state.displayColorPicker
                        ? <div style={popover} >
                            <div style={cover} onClick={this.pickerClose} />
                            <SketchPicker color={this.state.textColor} onChangeComplete={this.setColor} />
                        </div>
                        : null
                    }
                    <div className="font-style-container">
                        <div className="colrsec" title="Rotate">
                            <img id="icon" src={process.env.PUBLIC_URL + '/assets/images/rotate.jpg'} height="20px" width="20px" onClick={this.rotate} style={{ cursor: "pointer" }} alt="" />
                        </div>
                        {!isObjectLocked ? <div className="colrsec" title="Lock">
                            <img id="icon" src={process.env.PUBLIC_URL + '/assets/images/lock.jpg'} height="20px" width="20px" onClick={this.lock} style={{ cursor: "pointer" }} alt="Click to Lock" />
                        </div>: ''}
                        {isObjectLocked ? <div className="colrsec" title="Unlock">
                            <img id="icon" src={process.env.PUBLIC_URL + '/assets/images/unlock.png'} height="20px" width="20px" onClick={this.unlock} style={{ cursor: "pointer" }} alt="Click to Unlock" />
                        </div>: ''}
                    </div>

                    <div className="font-style-container">
                        <div title="Bold" onClick={this.setTextBold} className="txtbold">
                            <img className="toolbar-icon tbold" src={require('../images/bold.png')} alt="" />
                        </div>
                        <div title="Italic" onClick={this.setTextItalic} className="txtitalic">
                            <img className="toolbar-icon titalic" src={require('../images/italic.png')} alt="" />
                        </div>
                        <div title="Underline" onClick={this.setTextUnderline} className="txtunder">
                            <img className="toolbar-icon tunder" src={require('../images/underline.png')} alt="" />
                        </div>
                        <div title="Strike out" onClick={this.setTextStrike} className="txtstrike">
                            <img className="toolbar-icon tstrike" src={require('../images/strikethrough.png')} alt="" />
                        </div>
                        <div title="Bulleted List" onClick={this.setBulletText} className="txtbullet">
                            <img className="toolbar-icon tbullet" src={require('../images/list-bullet.png')} alt="" />
                        </div>
                        <div title="Ordered List" onClick={this.setNumberText} className="txtNumber">
                            <img className="toolbar-icon tNumber" src={require('../images/orderlist.jpg')} alt="" />
                        </div>
                    </div>

                    <div className="font-style-container">
                        <div title="Left" onClick={this.alignObjectLeft} className="txtleft">
                            <img className="toolbar-icon tleft" src={require('../images/align-to-left.png')} alt="" />
                        </div>
                        <div title="Center" onClick={this.alignObjectCenter} className="txtcenter">
                            <img className="toolbar-icon tcenter" src={require('../images/center-paragraph.png')} alt="" />
                        </div>
                        <div title="Right" onClick={this.alignObjectRight} className="txtright">
                            <img className="toolbar-icon tright" src={require('../images/align-to-right.png')} alt="" />
                        </div>
                    </div>

                    <div className="overlap-container">
                        <div title="Send Back" onClick={this.sendBackward} className="sendback">
                            <img className="overlapArrow" src={require('../images/send-backward.svg')} alt="" />
                        </div>
                        <div title="Bring Forward" onClick={this.bringForward} className="sendforward ">
                            <img className="overlapArrow" src={require('../images/bring-forward.svg')} alt="" />
                        </div>
                    </div>

                    <div className="overlap-container">
                        <div title="Group" onClick={this.groupItems} className="sendforward group">
                            <i className="arrow fa fa-object-group" aria-hidden="true"></i>
                        </div>
                        <div title="Ungroup" onClick={this.unGroupItems} className="sendforward ungroup">
                            <i className="arrow fa fa-object-ungroup" aria-hidden="true"></i>
                        </div>
                    </div>

                    {/* <div className="overlap-container">
                        <div title="Horizontalflip" onClick={this.horizontalflip} className="horizontalflip">
                            <i className="fas fa-ruler-vertical fa-rotate-270" aria-hidden="true"></i>
                        </div>
                        <div title="Verticalflip" onClick={this.verticalflip} className="verticalflip">
                            <i className="fas fa-ruler-vertical"></i>
                        </div>
                    </div> */}

                    {/* <div title="Effects" className="txteff">
                        <Popup onOpen={this.popupOpen} onClose={this.popupClose} className="popupcontent"
                            trigger={<div className="toolbar-label">Effects</div>}
                            position="bottom center"
                            closeOnDocumentClick
                        >
                            <div className="effects">
                                <div className="opacity-section">
                                    <div className="toolbar-label opacity">Opacity</div>
                                    <div className="slider-view">
                                        <input
                                            type="range"
                                            className="slider opacityslider"
                                            max="100"
                                            min="0"
                                            step="5"
                                            onChange={this.setOpacity}
                                            value={this.state.opacityval}
                                        />
                                        <div>{this.state.opacityval}%</div>
                                    </div>
                                </div>

                                <div className="effsection separator">
                                    <div className="toolbar-label">Outline</div>
                                    <div className="control">
                                        <input type="checkbox" id="switch" onChange={this.outlineCheckBox} checked={this.state.outlinechecked} />
                                        <label htmlFor="switch" onClick={this.outlinetoggle}>Toggle</label>
                                    </div>
                                </div>
                                <Collapse isOpen={this.statecollapse} className="strokesection">
                                    <div className="effsection">
                                        <div className="toolbar-label">Color</div>
                                        <div className="font-color-container">
                                            <div className="colrsec" onClick={this.strokepickerOpen}>
                                                <div ref={this.textstrokecolRef} className="primcol" style={{ background: this.state.backgroundcol }} />
                                                <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                            </div>
                                        </div>
                                        {this.state.displaystrokeColorPicker
                                            ? <div style={strokepopover}>
                                                <div style={strokecover} onClick={this.strokepickerClose} />
                                                <SketchPicker color={this.state.strokeColor} onChangeComplete={this.setStroke} />
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className="effsection">
                                        <div className="toolbar-label">Width</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider widthslider"
                                                max="5"
                                                min="1"
                                                step="1"
                                                onChange={this.setStrokeval}
                                                value={this.state.strokeval}
                                            />
                                            <div>{this.state.strokeval}</div>
                                        </div>
                                    </div>
                                </Collapse>

                                <div className="effsection separator">
                                    <div className="toolbar-label">Glow</div>
                                    <div className="control">
                                        <input type="checkbox" id="switch1" onChange={this.glowCheckBox} checked={this.state.glowchecked} />
                                        <label htmlFor="switch1" onClick={this.glowtoggle}>Toggle</label>
                                    </div>
                                </div>
                                <Collapse isOpen={this.state.glowcollapse} className="glowsection">
                                    <div className="effsection">
                                        <div className="toolbar-label">Color</div>
                                        <div className="font-color-container">
                                            <div className="colrsec" onClick={this.glowpickerOpen}>
                                                <div ref={this.textglowcolRef} className="primcol" style={{ background: this.state.glowbackgroundcol }} />
                                                <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                            </div>
                                        </div>
                                        {this.state.displayglowColorPicker
                                            ? <div style={glowpopover}>
                                                <div style={glowcover} onClick={this.glowpickerClose} />
                                                <SketchPicker color={this.state.glowColor} onChangeComplete={this.setGlow} />
                                            </div>
                                            : null
                                        }
                                    </div>
                                    <div className="effsection">
                                        <div className="toolbar-label">offsetX</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider widthslider"
                                                max="10"
                                                min="1"
                                                step="1"
                                                onChange={this.setglowoffsetX}
                                                value={this.state.offsetX}
                                            />
                                            <div>{this.state.offsetX}%</div>
                                        </div>
                                    </div>
                                    <div className="effsection">
                                        <div className="toolbar-label">offsetY</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider widthslider"
                                                max="10"
                                                min="1"
                                                step="1"
                                                onChange={this.setglowoffsetY}
                                                value={this.state.offsetY}
                                            />
                                            <div>{this.state.offsetY}%</div>
                                        </div>
                                    </div>
                                    <div className="effsection">
                                        <div className="toolbar-label">Blur</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider widthslider"
                                                max="10"
                                                min="1"
                                                step="1"
                                                onChange={this.setglowblur}
                                                value={this.state.blurval}
                                            />
                                            <div>{this.state.blurval}%</div>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </Popup>
                    </div> */}

                    {/* <div title="Effects" className="elementeff">
                        <Popup className="elepopupcontent"
                            trigger={<div className="toolbar-label">Effects</div>}
                            position="bottom center"
                            closeOnDocumentClick
                        >
                            <div className="effects">
                                <div className="opacity-section">
                                    <div className="toolbar-label opacity">Opacity</div>
                                    <div className="slider-view">
                                        <input
                                            type="range"
                                            className="slider opacityslider"
                                            max="100"
                                            min="0"
                                            step="5"
                                            onChange={this.setOpacity}
                                            value={this.state.opacityval}
                                        />
                                        <div>{this.state.opacityval}%</div>
                                    </div>
                                </div>

                            </div>
                        </Popup>
                    </div> */}

                    <div title="Effects" className="strokeeff">
                        <Popup className="popupcontent"
                            trigger={<div className="toolbar-label">Stroke</div>}
                            position="bottom center"
                            closeOnDocumentClick>
                            <span>
                                <div className="effects">
                                    <div className="effsection">
                                        <div className="toolbar-label stroke-label">Stroke Color</div>
                                        <div className="font-color-container">
                                            <div className="colrsec" onClick={this.strokepickerOpen}>
                                                <div ref={this.textstrokecolRef} className="primcol strokecolpick" />
                                                <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                            </div>
                                            {
                                                this.state.displaystrokeColorPicker
                                                    ? <div style={strokepopover} className="strokecolpic">
                                                        <div style={strokecover} onClick={this.strokepickerClose} />
                                                        <SketchPicker color={this.state.strokeColor} onChangeComplete={this.setStroke} />
                                                    </div>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="effsection">
                                        <div className="toolbar-label stroke-label">Stroke Width</div>
                                        <div className="slider-view">
                                            <input
                                                type="range"
                                                className="slider strokeslider"
                                                max="10"
                                                min="1"
                                                step="1"
                                                onChange={this.setStrokeval}
                                                value={this.state.strokeval}
                                            />
                                            <div>{this.state.strokeval}%</div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </Popup>
                    </div>
                </div>
                <div className="rightbar">
                    {/* <div>W:<input className="width" style={{ width: "70px", border: "1px solid #c1c1c1" }} type="number" name="width" id="width" onChange={this.setWidth} min="200" max="5000" step="5" /></div>
                    <div>H:<input style={{ width: "70px", border: "1px solid #c1c1c1" }} type="number" name="height" id="height" value={activeObjectHeight} onChange={this.setHeight} /></div> */}
                    <div title="Clone the Item" className="toolbar-label btn-duplicate" onClick={this.clone}>Clone</div>
                    <div title="Delete" className="btn-delete" onClick={this.deleteItem}>
                        <span><i className="far fa-trash-alt text-danger arrow"></i></span>
                    </div>
                </div>
            </Container>:
            <Container className="toolbar-container" style={{minHeight:"77px"}}>

                   <div className="row ">
                      
                    <div className='col-12' style={{textAlign:"left"}}>
                       <span title="Font Family" className="" >
                           <FontPicker
                               ref={this.pickerRef}
                               apiKey="AIzaSyCOyeDUsAnL-jnWudXBKNNma9cXmXsT4tM"
                               activeFontFamily={this.state.activeFontFamily}
                               limit="150"
                               onChange={nextFont => this.setTextFont(nextFont.family)}
                            
                           />
                       </span>
                        
                       <span className="" title="Font Size">
                           &nbsp;&nbsp;<select onChange={this.fontSize} value={this.state.value} style={{ border: '2px solid #ECECEC' }}>
                               <option>6</option>
                               <option>8</option>
                               <option>10</option>
                               <option>12</option>
                               <option>14</option>
                               <option>16</option>
                               <option>18</option>
                               <option>21</option>
                               <option>24</option>
                               <option>28</option>
                               <option>32</option>
                               <option>36</option>
                               <option>42</option>
                               <option>48</option>
                               <option>56</option>
                               <option>64</option>
                               <option>72</option>
                               <option>80</option>
                               <option>88</option>
                               <option>96</option>
                               <option>104</option>
                               <option>120</option>
                               <option>144</option>
                           </select>
   
                       </span>
                       </div>

                       <div className='col-12 py-1' style={{textAlign:"left"}}>
                       <span className="px-1">
                           <span title="Left" onClick={this.alignObjectLeft} className="px-1">
                               <img className="" style={{height:"20px",width:"20px"}} src={require('../images/align-to-left.png')} alt="" />
                           </span>
                           <span title="Center" onClick={this.alignObjectCenter} className="px-1">
                               <img className="" style={{height:"20px",width:"20px"}} src={require('../images/center-paragraph.png')} alt="" />
                           </span>
                           <span title="Right" onClick={this.alignObjectRight} className="px-1">
                               <img className="" style={{height:"20px",width:"20px"}} src={require('../images/align-to-right.png')} alt="" />
                           </span>
                       </span>
   
                       <span className="px-1">
                           <span title="Send Back" onClick={this.sendBackward} className="px-1">
                               <img className="" style={{height:"20px",width:"20px"}} src={require('../images/send-backward.svg')} alt="" />
                           </span>
                           <span title="Bring Forward" onClick={this.bringForward} className="px-1">
                               <img className="" style={{height:"20px",width:"20px"}} src={require('../images/bring-forward.svg')} alt="" />
                           </span>
                       </span>
   
                       <span className="px-1">
                           <span title="Group" onClick={this.groupItems} className="px-1">
                               <i className="arrow fa fa-object-group" aria-hidden="true" style={{height:"20px",width:"20px"}}></i>
                           </span>
                           <span title="Ungroup" onClick={this.unGroupItems} className="px-1">
                               <i className="arrow fa fa-object-ungroup" aria-hidden="true" style={{height:"20px",width:"20px"}}></i>
                           </span>
                       </span>
                       <span className="px-2">
                       {/* <div>W:<input className="width" style={{ width: "70px", border: "1px solid #c1c1c1" }} type="number" name="width" id="width" onChange={this.setWidth} min="200" max="5000" step="5" /></div>
                       <div>H:<input style={{ width: "70px", border: "1px solid #c1c1c1" }} type="number" name="height" id="height" value={activeObjectHeight} onChange={this.setHeight} /></div> */}
                       <span title="Clone the Item" className="mr-1" onClick={this.clone}>Clone</span>
                       <span title="Delete" className="btn-delete" onClick={this.deleteItem}>
                           <span><i className="far fa-trash-alt text-danger arrow"></i></span>
                       </span>
                   </span>
                       </div>
                       
                       <div className='col-12 pt-2' style={{textAlign:"left"}}>
                       <span className="px-1">
                     
                 
                       {/* <span className="font-color-container colorpic px-1" onClick={this.pickerOpen} title="Font/Fill Color">
                           <span className="colrsec" onClick={this.pickerOpen}>
                               <div ref={this.textcolorRef} className="primcol textcolpick" />
                               &nbsp;&nbsp;<img className="arrowimg  textcolpick" style={{width:"20px",height:"20px"}} src={require('../images/down-arrow.png')} alt="" onClick={this.pickerOpen} />
                           </span>
                           {this.state.displayColorPicker
                           ? <span style={popover} >
                               <div style={cover} onClick={this.pickerClose} />
                               <SketchPicker color={this.state.textColor} onChangeComplete={this.setColor} />
                           </span>
                           : null
                       }
                       </span> */}

                       
                       
                 
                           <span className="colrsec px-1" title="Rotate">
                               <img id="icon" src={process.env.PUBLIC_URL + '/assets/images/rotate.jpg'} height="20px" width="20px" onClick={this.rotate} style={{ cursor: "pointer" }} alt="" />
                           </span>
                           <span className="colrsec px-1" title="Lock">
                               <img id="icon" src={process.env.PUBLIC_URL + '/assets/images/lock.jpg'} height="20px" width="20px" onClick={this.lock} style={{ cursor: "pointer" }} alt="" />
                           </span>
                           <span className="colrsec px-1" title="Unlock">
                               <img id="icon" src={process.env.PUBLIC_URL + '/assets/images/unlock.png'} height="20px" width="20px" onClick={this.unlock} style={{ cursor: "pointer" }} alt="" />
                           </span>
                           <span className="px-1" onClick={this.pickerOpen} style={{width:"20px",height:"20px"}} title="Font/Fill Color">
                           <span className="" onClick={this.pickerOpen}>
                               {/* <span ref={this.textcolorRef} className="" style={{border:"0.5px solid black"}}/> */}
                             <img className="  " style={{width:"10px",height:"10px"}} src={require('../images/down-arrow.png')} alt="" onClick={this.pickerOpen} />
                             {this.state.displayColorPicker
                           ?
                           <span style={popover2} >
                               <span style={cover} onClick={this.pickerClose} />
                               <SketchPicker color={this.state.textColor} onChangeComplete={this.setColor} />
                           </span>
                           : null}
                           </span>

                       </span>
                       </span>
   
                       <span className="px-1">
                           <span title="Bold" onClick={this.setTextBold} className=" px-1">
                               <img className="toolbar-icon tbold" style={{height:"20px",width:"20px"}} src={require('../images/bold.png')} alt="" />
                           </span>
                           <span title="Italic" onClick={this.setTextItalic} className=" px-1">
                               <img className="toolbar-icon titalic" style={{height:"20px",width:"20px"}} src={require('../images/italic.png')} alt="" />
                           </span>
                           <span title="Underline" onClick={this.setTextUnderline} className=" px-1">
                               <img className="toolbar-icon tunder" style={{height:"20px",width:"20px"}} src={require('../images/underline.png')} alt="" />
                           </span>
                           <span title="Strike out" onClick={this.setTextStrike} className=" px-1">
                               <img className="toolbar-icon tstrike" style={{height:"20px",width:"20px"}} src={require('../images/strikethrough.png')} alt="" />
                           </span>
                           <span title="Bulleted List" onClick={this.setBulletText} className="px-1">
                               <img className="toolbar-icon tbullet" style={{height:"20px",width:"20px"}} src={require('../images/list-bullet.png')} alt="" />
                           </span>
                           <span title="Ordered List" onClick={this.setNumberText} className="px-1">
                               <img className="toolbar-icon tNumber" style={{height:"20px",width:"20px"}} src={require('../images/orderlist.jpg')} alt="" />
                           </span>
                       </span>
                       </div>

                     
   
                       {/* <div className="overlap-container">
                           <div title="Horizontalflip" onClick={this.horizontalflip} className="horizontalflip">
                               <i className="fas fa-ruler-vertical fa-rotate-270" aria-hidden="true"></i>
                           </div>
                           <div title="Verticalflip" onClick={this.verticalflip} className="verticalflip">
                               <i className="fas fa-ruler-vertical"></i>
                           </div>
                       </div> */}
   
                       {/* <div title="Effects" className="txteff">
                           <Popup onOpen={this.popupOpen} onClose={this.popupClose} className="popupcontent"
                               trigger={<div className="toolbar-label">Effects</div>}
                               position="bottom center"
                               closeOnDocumentClick
                           >
                               <div className="effects">
                                   <div className="opacity-section">
                                       <div className="toolbar-label opacity">Opacity</div>
                                       <div className="slider-view">
                                           <input
                                               type="range"
                                               className="slider opacityslider"
                                               max="100"
                                               min="0"
                                               step="5"
                                               onChange={this.setOpacity}
                                               value={this.state.opacityval}
                                           />
                                           <div>{this.state.opacityval}%</div>
                                       </div>
                                   </div>
   
                                   <div className="effsection separator">
                                       <div className="toolbar-label">Outline</div>
                                       <div className="control">
                                           <input type="checkbox" id="switch" onChange={this.outlineCheckBox} checked={this.state.outlinechecked} />
                                           <label htmlFor="switch" onClick={this.outlinetoggle}>Toggle</label>
                                       </div>
                                   </div>
                                   <Collapse isOpen={this.statecollapse} className="strokesection">
                                       <div className="effsection">
                                           <div className="toolbar-label">Color</div>
                                           <div className="font-color-container">
                                               <div className="colrsec" onClick={this.strokepickerOpen}>
                                                   <div ref={this.textstrokecolRef} className="primcol" style={{ background: this.state.backgroundcol }} />
                                                   <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                               </div>
                                           </div>
                                           {this.state.displaystrokeColorPicker
                                               ? <div style={strokepopover}>
                                                   <div style={strokecover} onClick={this.strokepickerClose} />
                                                   <SketchPicker color={this.state.strokeColor} onChangeComplete={this.setStroke} />
                                               </div>
                                               : null
                                           }
                                       </div>
                                       <div className="effsection">
                                           <div className="toolbar-label">Width</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider widthslider"
                                                   max="5"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setStrokeval}
                                                   value={this.state.strokeval}
                                               />
                                               <div>{this.state.strokeval}</div>
                                           </div>
                                       </div>
                                   </Collapse>
   
                                   <div className="effsection separator">
                                       <div className="toolbar-label">Glow</div>
                                       <div className="control">
                                           <input type="checkbox" id="switch1" onChange={this.glowCheckBox} checked={this.state.glowchecked} />
                                           <label htmlFor="switch1" onClick={this.glowtoggle}>Toggle</label>
                                       </div>
                                   </div>
                                   <Collapse isOpen={this.state.glowcollapse} className="glowsection">
                                       <div className="effsection">
                                           <div className="toolbar-label">Color</div>
                                           <div className="font-color-container">
                                               <div className="colrsec" onClick={this.glowpickerOpen}>
                                                   <div ref={this.textglowcolRef} className="primcol" style={{ background: this.state.glowbackgroundcol }} />
                                                   <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                               </div>
                                           </div>
                                           {this.state.displayglowColorPicker
                                               ? <div style={glowpopover}>
                                                   <div style={glowcover} onClick={this.glowpickerClose} />
                                                   <SketchPicker color={this.state.glowColor} onChangeComplete={this.setGlow} />
                                               </div>
                                               : null
                                           }
                                       </div>
                                       <div className="effsection">
                                           <div className="toolbar-label">offsetX</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider widthslider"
                                                   max="10"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setglowoffsetX}
                                                   value={this.state.offsetX}
                                               />
                                               <div>{this.state.offsetX}%</div>
                                           </div>
                                       </div>
                                       <div className="effsection">
                                           <div className="toolbar-label">offsetY</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider widthslider"
                                                   max="10"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setglowoffsetY}
                                                   value={this.state.offsetY}
                                               />
                                               <div>{this.state.offsetY}%</div>
                                           </div>
                                       </div>
                                       <div className="effsection">
                                           <div className="toolbar-label">Blur</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider widthslider"
                                                   max="10"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setglowblur}
                                                   value={this.state.blurval}
                                               />
                                               <div>{this.state.blurval}%</div>
                                           </div>
                                       </div>
                                   </Collapse>
                               </div>
                           </Popup>
                       </div> */}
   
                       {/* <div title="Effects" className="elementeff">
                           <Popup className="elepopupcontent"
                               trigger={<div className="toolbar-label">Effects</div>}
                               position="bottom center"
                               closeOnDocumentClick
                           >
                               <div className="effects">
                                   <div className="opacity-section">
                                       <div className="toolbar-label opacity">Opacity</div>
                                       <div className="slider-view">
                                           <input
                                               type="range"
                                               className="slider opacityslider"
                                               max="100"
                                               min="0"
                                               step="5"
                                               onChange={this.setOpacity}
                                               value={this.state.opacityval}
                                           />
                                           <div>{this.state.opacityval}%</div>
                                       </div>
                                   </div>
   
                               </div>
                           </Popup>
                       </div> */}
       
                       <span title="Effects" className="strokeeff">
                           <Popup className="popupcontent"
                               trigger={<div className="toolbar-label">Stroke</div>}
                               position="bottom center"
                               closeOnDocumentClick>
                               <span>
                                   <div className="effects">
                                       <div className="effsection">
                                           <div className="toolbar-label stroke-label">Stroke Color</div>
                                           <div className="font-color-container">
                                               <div className="colrsec" onClick={this.strokepickerOpen}>
                                                   <div ref={this.textstrokecolRef} className="primcol strokecolpick" />
                                                   <img className="arrowimg" src={require('../images/down-arrow.png')} alt="" />
                                               </div>
                                               {
                                                   this.state.displaystrokeColorPicker
                                                       ? <div style={strokepopover} className="strokecolpic">
                                                           <div style={strokecover} onClick={this.strokepickerClose} />
                                                           <SketchPicker color={this.state.strokeColor} onChangeComplete={this.setStroke} />
                                                       </div>
                                                       : null
                                               }
                                           </div>
                                       </div>
                                       <hr />
                                       <div className="effsection">
                                           <div className="toolbar-label stroke-label">Stroke Width</div>
                                           <div className="slider-view">
                                               <input
                                                   type="range"
                                                   className="slider strokeslider"
                                                   max="10"
                                                   min="1"
                                                   step="1"
                                                   onChange={this.setStrokeval}
                                                   value={this.state.strokeval}
                                               />
                                               <div>{this.state.strokeval}%</div>
                                           </div>
                                       </div>
                                   </div>
                               </span>
                           </Popup>
                       </span>
                   </div>
              
          </Container>
            }
    </div>
        );
    }
}

export default Toolbar;
