import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import $ from "jquery";


import { fabric } from "fabric";
import { Row, Col, Container,Navbar,Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Collapse } from "reactstrap";
import classnames from 'classnames';

import { Tabs, Tab, TabList } from "react-web-tabs";
// import queryString from 'query-string';
import {productTypes} from './constants';
//import { BrowserRouter, Route, Switch } from 'react-router-dom';
import FabricCanvas from "./components/FabricCanvas";
import Toolbar from "./components/Toolbar";
import LeftPanel from "./components/LeftPanel";
import logo from "./images/lamcraft_logo.png";

//import Footer from './components/Footer';
import config from "../config";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { templates,VERSES } from "./constants";
import { getObjectByPropValue } from "./components/Helpers"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ListGroup from "reactstrap/lib/ListGroup";

//import $ from 'jquery';
import "./App.scss";
import "./styles/Navbar.scss";
import "./styles/TabView.scss";
import "./styles/LeftSidePanel.scss";
import "./styles/Footer.scss";
import "./styles/FabricCanvas.scss";
import "./styles/Toolbar.scss";
import "./components/modal.css";
import './styles/Responsivemedia.scss'
import {setcanvasBG} from "./components/Helpers";
fabric.Canvas.prototype.aspectRation = 1
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allCanvasList: [],
            canvas: null,
            canvasObj: [],
            isSnap: false,
            isOverlap: false,
            productType: null,
            bookmarkType: "1",
            bookmarkComment: '2.75" w x 8.5" h (4 on a Page)',
            isGrid: false,
            sidebarWidth: 367,
            canvaswidth: "",
            canvasheight: "",
            //defaultbg: require('./images/main-img.jpg'),
            fontBoldValue: "normal",
            fontStrikeValue: "",
            fontItalicValue: "",
            fontUnderlineValue: "",
            collapse: true,
            gridsize: 30,
            imggallery: [],
            top: "",
            isOpen: false,
            currentBookmark: "",
            templateKey : null,
            activeTab: '',
            toolbarVisible:false,
        };
        this.currentBookmark = this.currentBookmark.bind(this);
        this.toggle = this.toggle.bind(this);
      
    }
    componentDidMount() {
        this.imgGallery();
        this.currentBookmark();
    }



componentDidUpdate=(prevProps,prevState)=>{
    console.log("prevSTate",this.state.toolbarVisible)
    if(prevState.canvas!==this.state.canvas)
    {
        this.state.canvas.off('selection:created')
        this.state.canvas.on('selection:created', (e) => {
        this.setState({toolbarVisible:true})
        })

        this.state.canvas.off('selection:updated')
        this.state.canvas.on('selection:updated', (e) => {
            this.setState({toolbarVisible:true})

        })

        this.state.canvas.off('selection:cleared')
        this.state.canvas.on('selection:cleared', (e) => {
            this.setState({toolbarVisible:false})

        })

}

}
  

    
    currentBookmark = (currentLocc = null) => {
       // let queryParam = window.location.href.split("/")[3];

        const query = new URLSearchParams(window.location.search);
        let template_key = query.get('t')
        const type = query.get('type')

        console.log('query',type, template_key)
        let productTypeKeys = Object.keys(productTypes)
        let currentLoc = this.state?.productType ? this.state.productType : type;
        if(!template_key){
            const default_template = getObjectByPropValue(templates, 'default', true)
            template_key = default_template['title'];
        }
        if (!productTypeKeys.includes(currentLoc)) {
            this.openModal();
        } else {
            const selectedProduct = productTypes[currentLoc]
            this.setState({
                currentBookmark: currentLoc,
                templateKey: template_key
            });
            console.log('selectedProduct',productTypes,currentLoc, selectedProduct)
            this.setState({
                canvaswidth: selectedProduct['canvaswidth'],
                canvasheight: selectedProduct['canvasheight'],
            });
        }
    };
    setTemplateKey = (key) => {
        this.setState({
            templateKey: key
        });
    }

    setActiveTab=(activeTabKey)=>{
        this.setState({
            activeTab:activeTabKey
        });
    }
    openModal = () => {
        this.setState({
            isOpen: true,
        });
    };
    closeModal = () => {
        this.setState({
            isOpen: false,
        });
    };
    productType = (event) => {
        var prodType = event.target.value;
        if (prodType <= 0) {
            this.setState({
                productType: 0,
                bookmarkComment: "",
            });
        } else {
            prodType = JSON.parse(prodType);
            this.setState({
                bookmarkComment: prodType.bookmarkComment,
                productType: prodType.value
            });
        }
    };
    updateCanvas = (canvas) => {
        this.setState(
            {
                canvas: canvas,
            },
            () => {
                //console.log("APP updateCanvas state:::", this.state);
                //console.log("APP updateCanvas canvas:::", canvas);
            }
        );
    };
    addCanvasInCanvasLIst = (item) => {
        this.state.allCanvasList.push(item);
    };
    updateCanvases = (side) => {
        //var arr=[];
        const item = this.state.allCanvasList?.find(
            (item) => item.side === side
        );

        this.updateCanvas(item.canvas);
        // canvases.forEach(element => {

        //     this.state.canvasObj.push(element)
        //     if(element == 'front' ){
        //         this.updateCanvas(this.state.canvasObj[0])
        //     }
        //     if(element == 'back' && element?.getElement().id == "back"){
        //         this.updateCanvas(this.state.canvasObj[1])
        //     }
        // });
    };

    updateState = (stateoptions) => {
        this.setState(stateoptions);
        this.currentBookmark();
        //console.log("stateoptions:::", stateoptions);
        console.log("this.state:::", this.state);
        //Optimization. This is not not required here
        //this.state.canvas.setDimensions({width: this.state.canvaswidth, height: this.state.canvasheight}, {cssOnly: true});
        //this.state.canvas.setDimensions({width: this.state.canvaswidth}, {cssOnly: true});
    };

    toggleSidebar = (type) => {
        this.setState({ collapse: type });

        this.setState({
            sidebarWidth: type ? 367 : 0,
        });
    };

    export = () => {
        let currentTime = new Date();
        let month = currentTime.getMonth() + 1;
        let day = currentTime.getDate();
        let year = currentTime.getFullYear();
        let hours = currentTime.getHours();
        let minutes = currentTime.getMinutes();
        let seconds = currentTime.getSeconds();
        let fileName =
            month +
            "" +
            day +
            "" +
            year +
            "" +
            hours +
            "" +
            minutes +
            "" +
            seconds;
        const canvasdata = document.getElementById("front");
        const canvasDataUrl = canvasdata
                .toDataURL()
                .replace(/^data:image\/[^;]*/, "data:application/octet-stream"),
            link = document.createElement("a");
        fileName = fileName + ".png";
        link.setAttribute("href", canvasDataUrl);
        link.setAttribute("crossOrigin", "anonymous");
        link.setAttribute("target", "_blank");
        link.setAttribute("download", fileName);
        if (document.createEvent) {
            let evtObj = document.createEvent("MouseEvents");
            evtObj.initEvent("click", true, true);
            link.dispatchEvent(evtObj);
        } else if (link.click) {
            link.click();
        }
    };

    downloadAsJSON = () => {
        let currentTime = new Date();
        let month = currentTime.getMonth() + 1;
        let day = currentTime.getDate();
        let year = currentTime.getFullYear();
        let hours = currentTime.getHours();
        let minutes = currentTime.getMinutes();
        let seconds = currentTime.getSeconds();
        let fileName =
            month +
            "" +
            day +
            "" +
            year +
            "" +
            hours +
            "" +
            minutes +
            "" +
            seconds;
        let canvasdata = this.state.canvas.toDatalessJSON();
        let string = JSON.stringify(canvasdata);
        let file = new Blob([string], {
            type: "application/json",
        });
        let a = document.createElement("a");
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    setBookmark = () => {
        if (this.state.productType <= 0) {
            this.setState({
                productType: 0,
                bookmarkComment: "",
                canvaswidth: 264,
                canvasheight: 816,
            });
        } else {
            const product = productTypes[this.state.productType]
            this.setState({
                bookmarkComment: product.bookmarkComment,
                canvaswidth: product.canvaswidth,
                canvasheight: product.canvasheight,
            });
            this.closeModal();
        }
    };
    setSnap = () => {
        this.setState({
            isSnap: !this.state.isSnap,
        });
        let offstate = document.querySelectorAll("#snapswitch");
        for (let j = 0; j < offstate.length; j++) {
            offstate[j].checked = this.state.isSnap;
        }
    };

    showhideGrid = () => {
        let isGrid = !this.state.isGrid;
        this.setState({
            isGrid: isGrid,
        });
        if (isGrid) {
            for (let i = 0; i < 650 / this.state.gridsize; i++) {
                this.state.canvas.add(
                    new fabric.Line(
                        [
                            i * this.state.gridsize,
                            0,
                            i * this.state.gridsize,
                            650,
                        ],
                        {
                            stroke: "#ccc",
                            selectable: false,
                        }
                    )
                );
                this.state.canvas.add(
                    new fabric.Line(
                        [
                            0,
                            i * this.state.gridsize,
                            650,
                            i * this.state.gridsize,
                        ],
                        {
                            stroke: "#ccc",
                            selectable: false,
                        }
                    )
                );
            }
        } else {
            this.clearGrid();
        }
        let offstate = document.querySelectorAll("#gridswitch");
        for (let j = 0; j < offstate.length; j++) {
            offstate[j].checked = this.state.isGrid;
        }
        this.state.canvas.renderAll();
    };

    clearGrid = () => {
        let objects = this.state.canvas.getObjects("line");
        for (let i in objects) {
            this.state.canvas.remove(objects[i]);
        }
    };

    setOverlap = () => {
        this.setState({
            isOverlap: !this.state.isOverlap,
        });
        let offoverlap = document.querySelectorAll("#overlapswitch");
        for (let j = 0; j < offoverlap.length; j++) {
            offoverlap[j].checked = this.state.isOverlap;
        }
    };
    imgGallery = () => {
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
        };

        //const {imggallery } = this.state;
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}/image_gallery?userId=1`;
        axios
            .get(apiUrl, {
                //mode: 'cors',
                headers: headers,
                rejectUnauthorized: true,
            })
            .then((response) => {
                this.setState({
                    imggallery: response.data.data,
                });
            })
            .catch((err) => {});
    };
    
    toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }

    render() {
        const {
            sidebarWidth,
            collapse,
            imggallery,
            bookmarkComment,
            productType,
        } = this.state;
        return (
            <div>
                {" "}
                <Container fluid>
                    <Row className="main-container">
              
                        <div className="tabpanel">
                   
                            <div className="largedevice">
                            <Row className="navbar-container">
                                <Col>
                                    <nav className="navbar navbar-expand-lg header-bar">
                                        <button
                                            className="navbar-toggler"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="{null}bs-example-navbar-collapse-1"
                                        >
                                            <span className="navbar-toggler-icon"></span>
                                        </button>
                                        <a className="navbar-brand" href="/">
                                            <img src={logo} alt="" />{" "}
                                        </a>
                                        {/* <div className="left-link"><span className="nav-link brand text-primary"><strong></strong></span></div> */}
                                    </nav>
                                </Col>
                                <Col>
                                    <nav className="navbar navbar-expand-lg header-bar">
                                        <div
                                            className="collapse navbar-collapse"
                                            id="bs-example-navbar-collapse-1"
                                        >
                                            <ul className="navbar-nav ml-md-auto">
                                                {/* <li className="nav-item active download">
                                        <span className="btn btn-outline" onClick={this.downloadAsJSON}>Export JSON</span>
                                    </li> */}
                                                {/* <li className="nav-item active download">
                                        <span className="btn btn-success" onClick={this.export}>Export</span>
                                    </li> */}
                                            </ul>
                                        </div>
                                    </nav>
                                </Col>
                            </Row>
                            </div>
                            <div className="largedevice">
                            <Tabs
                                defaultTab="vertical-tab-six"
                                vertical
                                className="vertical-tabs "
                            >
                                <TabList>
                                    <Tab
                                        tabFor="vertical-tab-six"
                                        className="lasttab"
                                        onClick={() => this.toggleSidebar(true)}
                                    >
                                        <div className="edit-box">
                                            <i className="fas fa-palette fa-2x text-muted"></i>
                                            <span className="sidenav">
                                                TEMPLATE
                                            </span>
                                        </div>
                                    </Tab>
                                    <Tab
                                        tabFor="vertical-tab-five"
                                        className="lasttab"
                                        onClick={() => this.toggleSidebar(true)}
                                    >
                                        <div className="edit-box">
                                            <i className="fas fa-image fa-2x text-muted"></i>
                                            <span className="sidenav">
                                                IMAGE
                                            </span>
                                        </div>
                                    </Tab>
                                    <Tab
                                        tabFor="vertical-tab-one"
                                        className="lasttab"
                                        onClick={() => this.toggleSidebar(true)}
                                    >
                                        <div className="edit-box">
                                            <i className="fas fa-font fa-2x text-muted"></i>
                                            <span className="sidenav">
                                                TEXT
                                            </span>
                                        </div>
                                    </Tab>
                                    <Tab
                                        tabFor="vertical-tab-eight"
                                        className="lasttab"
                                        onClick={() => this.toggleSidebar(true)}
                                    >
                                        <div className="edit-box">
                                            <i className="fas fa-font fa-2x text-muted"></i>
                                            <span className="sidenav">
                                                Verse
                                            </span>
                                        </div>
                                    </Tab>
                                    {/* <Tab tabFor="vertical-tab-two" className="lasttab" onClick={() => this.toggleSidebar(true)}>
                                <div className="edit-box">
                                    <i className="fas fa-fill fa-2x text-muted"></i>
                                    <span className="sidenav">COLOR</span>
                                </div>
                            </Tab> */}
                                    {/* <Tab tabFor="vertical-tab-three" className="lasttab" onClick={() => this.toggleSidebar(true)}>
                                <div className="edit-box">
                                    <i className="fas fa-images fa-2x text-muted"></i>
                                    <span className="sidenav" >CLIP ARTS</span>
                                </div>
                            </Tab> */}
                                    {/* <Tab tabFor="vertical-tab-four" className="lasttab" onClick={() => this.toggleSidebar(true)}>
                                <div className="edit-box">
                                    <i className="fas fa-shapes fa-2x text-muted"></i>
                                    <span className="sidenav">SHAPES</span>
                                </div>
                            </Tab> */}
                                    <Tab
                                        tabFor="vertical-tab-seven"
                                        className="lasttab"
                                        onClick={() => this.toggleSidebar(true)}
                                    >
                                        <div className="edit-box">
                                            <i className="fas fa-print fa-2x text-muted"></i>
                                            <span className="sidenav">
                                                PRINT
                                            </span>
                                        </div>
                                    </Tab>
                                </TabList>
                                <div
                                    style={{ width: sidebarWidth }}
                                    className="left-side-panel"
                                >
                                    {this.state.templateKey && collapse && (
                                        <LeftPanel
                                            key="leftpanel"
                                            allCanvasList={
                                                this.state.allCanvasList
                                            }
                                            canvas={this.state.canvas}
                                            canvasObj={this.state.canvasObj}
                                            imggallery={imggallery}
                                            imgGallery={this.imgGallery}
                                            currentBookmark={this.state.currentBookmark}
                                            templateSize={{
                                                width:this.state.canvaswidth,
                                                height:this.state.canvasheight
                                            }}
                                            templateKey= {this.state.templateKey}
                                            setTemplateKey = {this.setTemplateKey}
                                        />
                                    )}
                                </div>
                                <div
                                    className="btn-toggle"
                                    onClick={() =>
                                        this.toggleSidebar(!this.state.collapse)
                                    }
                                    style={{ opacity: collapse ? 1 : 0 }}
                                >
                                    <i className="fas fa-chevron-left arrowimage"></i>
                                </div>
                            </Tabs>
                            </div>
                        </div>

                        <div className="canvas-panel">
                            <div className="canvas-panel-header-toolbar ">
                                {$(window).width()>1100?
                                   <Toolbar
                                   state={this.state}
                                   updateCanvas={this.updateCanvas}
                                   canvasObj={this.state.canvasObj}
                               />: (this.state.toolbarVisible)? 
                               <Toolbar
                               state={this.state}
                               updateCanvas={this.updateCanvas}
                               canvasObj={this.state.canvasObj}
                           /> :    <a className="" style={{marginLeft:"30%"}} href="/">
                           <img src={logo} alt="" />{" "}
                       </a>
                                     
                            }
                      

                          
                           

                            </div>

                            <div className="canvas-panel-canvas-area">
                                {/* Create Canvas after getting the size */}
                                {this.state.canvaswidth !== '' ? (
                                    <FabricCanvas
                                        state={this.state}
                                        currentBookmark={this.currentBookmark}
                                        updateCanvas={this.updateCanvas}
                                        addCanvasInCanvasLIst={
                                            this.addCanvasInCanvasLIst
                                        }
                                        updateCanvases={this.updateCanvases}
                                        updateState={this.updateState}
                                    />
                                ) : null}
                            </div>
                            {/*<Footer canvas={this.state.canvas}>
                        <ul className="navbar-nav ml-md-auto">
                            <li className="nav-item">
                                <a className="nav-link switch" href="{null}" title="Display Grid">Grid <input type="checkbox" id="gridswitch" />
                                    <label htmlFor="gridswitch" onClick={this.showhideGrid}>Toggle</label>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link switch" href="{null}" title="Snap to Grid">Snap <input type="checkbox" id="snapswitch" />
                                    <label htmlFor="snapswitch" onClick={this.setSnap}>Toggle</label>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link switch" href="{null}" title="Overlap">Overlap <input type="checkbox" id="overlapswitch" />
                                    <label htmlFor="overlapswitch" onClick={this.setOverlap}>Toggle</label>
                                </a>
                            </li>
                        </ul>
                    </Footer>*/}
                        </div>
                      
                    </Row>
                 
        <div className="mobilenav fixed-bottom ">
       
        <Tabs>
        <div
                                    className=" fixed-bottom" style={{position:"relative",zIndex:"auto"} }
                                >
                                    {this.state.templateKey && collapse && (
                                        <LeftPanel
                                            key="leftpanel"
                                            allCanvasList={
                                                this.state.allCanvasList
                                            }
                                            canvas={this.state.canvas}
                                            canvasObj={this.state.canvasObj}
                                            imggallery={imggallery}
                                            imgGallery={this.imgGallery}
                                            currentBookmark={this.state.currentBookmark}
                                            templateSize={{
                                                width:this.state.canvaswidth,
                                                height:this.state.canvasheight
                                            }}
                                            activeTab={this.state.activeTab}
                                            templateKey= {this.state.templateKey}
                                            setTemplateKey = {this.setTemplateKey}
                                            setActiveTab={this.setActiveTab}
                                        />
                                    )}
                                </div>
       </Tabs>
    
       
        <nav className="menu" style={{border:"none",display:"flex",overflowX:"scroll",listStyle:"none",height:"100px"}}>
          <NavItem
                    className={classnames({selectedColor: this.state.activeTab === '1'})}

          >
              
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { 
                if(this.state.activeTab!=="1")
                {  
                this.toggle('1'); 
                }
        
                else{
                    this.toggle('');
                }
            }
        }
            >
                <span className="edit-box">
            <i className="fas fa-font fa-2x px-1" style={{color:this.state.activeTab==='1'?"white":"grey"}}></i>
            <span>
            TEXT
           </span>
            </span>
              
            </NavLink>
          </NavItem>
          <NavItem
                    className={classnames({selectedColor: this.state.activeTab === '2'})}

          >
            <NavLink
              className={classnames({active: this.state.activeTab === '2' })}
              onClick={() => {
                  
                if(this.state.activeTab!=="2")
                {  
                this.toggle('2'); 
                }
        
                else{
                    this.toggle('');
                }
             }}
            >
                <span className="edit-box">
                <i className="fas fa-palette fa-2x  px-4" style={{color:this.state.activeTab==='2'?"white":"grey"}}></i>
              <span className="side-nav">TEMPLATES</span>
              </span>
            </NavLink>
          </NavItem>
          <NavItem 
          className={classnames({selectedColor: this.state.activeTab === '3'})}
          >
            <NavLink
              className={classnames({active: this.state.activeTab === '3' })}
              onClick={() => {
                if(this.state.activeTab!=="3")
                {  
                this.toggle('3'); 
                }
        
                else{
                    this.toggle('');
                }
                }}
            >
        <span className="edit-box">
        <i className="fas fa-image fa-2x  px-2" style={{color:this.state.activeTab==='3'?"white":"grey"}}></i>

         <span className="side-nav">IMAGE</span>
       </span>
            </NavLink>
          </NavItem>
          <NavItem
                    className={classnames({selectedColor: this.state.activeTab === '4'})}
                    >
            <NavLink
              className={classnames({active: this.state.activeTab === '4' })}
              onClick={() => {     
            if(this.state.activeTab!=="4")
              {  
              this.toggle('4'); 
              }
      
              else{
                  this.toggle('');
              }
             }}
            >
                <span className="edit-box">
                <i className="fas fa-font fa-2x  px-2" style={{color:this.state.activeTab==='4'?"white":"grey"}}></i>

                    <span>
                    VERSE
                    </span>
                </span>
              
            </NavLink>
          </NavItem>
          <NavItem
                    className={classnames({selectedColor: this.state.activeTab === '5'})}

          >
            <NavLink
              className={classnames({active: this.state.activeTab === '5' })}
              onClick={() => { 
                if(this.state.activeTab!=="5")
                {  
                this.toggle('5'); 
                }
        
                else{
                    this.toggle('');
                }
             }}
            >
                <span className="edit-box">
                <i className="fas fa-print fa-2x " style={{color:this.state.activeTab==='5'?"white":"grey"}}></i>

                    <span className="side-nav">
                    PRINT

                    </span>
                </span>
            </NavLink>
          </NavItem>
        </nav>
        
    
      </div>
     
                </Container>

      
                          
                <Modal
                    show={this.state.isOpen}
                    onHide={this.closeModal}
                    size="default"
                    id="productTypeModal"
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span style={{ fontSize: "24px" }}>
                                No Product!
                            </span>
                        </Modal.Title>
                        <div
                            className=""
                            style={{ position: "absolute", marginLeft: "60%" }}
                        >
                            &nbsp;
                        </div>{" "}
                        {this.state.productType !== 0 ? (
                            <span
                                title="close"
                                style={{ cursor: "pointer" }}
                                onClick={this.closeModal}
                            >
                                X
                            </span>
                        ) : (
                            ""
                        )}
                    </Modal.Header>
                    <Modal.Body>
                    No product selected, please try again
                        {/* {this.state.productType !== null && this.state.productType <= 0 && (
                            <div className="row">
                                <div className="col-12">
                                    <div
                                        className="alert alert-danger"
                                        style={{ width: "100%" }}
                                    >
                                        <strong className="mr-2">Note:</strong>Please
                                        select Product Type.
                                    </div>

                                </div>
                            </div>

                        )}
                        <div className="row">
                            <div
                                className="row-md-6"
                                style={{ width: "68%", paddingLeft: "3%" }}
                            >
                                <select
                                    className="productType"
                                    onChange={this.productType}
                                    defaultValue={productType}
                                >
                                    <option value="0">
                                        --Select Product Type--
                                    </option>
                                    {Object.values(productTypes).map((productType, offset) => (
                                        <option
                                            value={JSON.stringify(productType)}
                                            key={offset}
                                        >
                                            {productType.name}
                                        </option>
                                    ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div
                            className="row"
                            style={{ width: "68%", paddingLeft: "3%" }}
                        >
                            {bookmarkComment}
                        </div> */}
                    </Modal.Body>
                    <Modal.Footer style={{ justifyContent: "center" }}>
                        {/* {productType !== 0 ? (
                            <button
                                type="button"
                                id="producttype"
                                className={`btn  ${!this.state.productType ?  "btn-disabled" : "btn-primary" }`}

                                disabled={!this.state.productType}
                                onClick={this.setBookmark}
                            >
                            <i className="fa fa-plus mr-2"></i>Create
                            </button>
                        ) : (
                            <>
                                <button
                                    type="button"
                                    id="producttype"
                                    className="btn btn-primary"
                                    disabled
                                >
                                    <i className="fa fa-plus"></i>
                                    &nbsp;&nbsp;Create
                                </button>
                            </>
                        )} */}

                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default App;
