'use strict';

// App config the for development environment.
export default {
     app: {
        host: 'http://localhost:3000',
        port:'3000',
        apiversion: ""   
    },
    api: {        
        host: 'http://localhost:5000',
        port:'5000',
        apiversion: ""        
    },
    // app: {
    //     host: 'https://lamcraft-front.cns72.com',
    //     port:'7050',
    //     apiversion: ""   
    // },
    // api: {        
    //     host: 'https://lamcraft-back.cns72.com',
    //     port:'7060',
    //     apiversion: ""        
    // },    
};