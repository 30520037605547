import React, { Component } from 'react';
import { fabric } from 'fabric';
import { saveCanvasState, initCenteringGuidelines, initAligningGuidelines, selectObject, pixelSize } from './Helpers'
import $ from 'jquery';
import "./modal.css";
import config from '../../config';
import axios from 'axios';
class FabricCanvas extends Component {

    constructor(props) {
        console.log("props:::", props)

        //var abs1,abs2;
        super(props);
        this.mainCanvasContainer = React.createRef()
        this.state = {
            displaybgColorPicker: false,
            subtarget: null,
            frontback:"",
            canvasObj: [],
            canvas:null,
            canvas1:null,
            canvasActive: [],
            imgParam: [],
            imgdata: [],
            canvasOuterMargin: "",
            canvasWidth: 220,
            canvasHeight: 420,
            initCanvasHeight: 220,
            initCanvasHeight: 420,
            zoomLevel:100,
            initZoomLevel:1

        };
        this.map__SetZoom = this.map__SetZoom.bind(this);
    }

    updateState(e) {
        let stateoptions = {};
        if (e) {
            stateoptions = {
                fontBoldValue: e.target.fontWeight,
                fontItalicValue: e.target.fontStyle,
                fontUnderlineValue: e.target.underline,
                fontStrikeValue: e.target.linethrough
            }
        }
        this.props.updateState(stateoptions);
    }

    componentDidMount() {
        console.log('this.mainCanvasContainer',this.mainCanvasContainer.current?.getBoundingClientRect())
        const container = this.mainCanvasContainer.current?.getBoundingClientRect()
        let userId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : 1;
        this.props.currentBookmark();
        //Convert inches to pixel
        let canvasWidthInPixels = pixelSize(this.props.state?.canvaswidth)
        let canvasHeightInPixels = pixelSize(this.props.state?.canvasheight)
        const scale =  Math.min((container.width-container.width*0.1)/canvasWidthInPixels, (container.height-container.height*0.1)/canvasHeightInPixels)

        const ratio = (container.height-100)/canvasHeightInPixels
        canvasWidthInPixels*=ratio*scale
        canvasHeightInPixels*=ratio*scale
   
        this.setState({
            canvasWidth:canvasWidthInPixels,
            canvasHeight:canvasHeightInPixels,
            initCanvasWidth:canvasWidthInPixels,
            initCanvasHeight:canvasHeightInPixels,
        })
        this.canvas = new fabric.Canvas('front', {
            preserveObjectStacking: true,
            width: canvasWidthInPixels,
            height: canvasHeightInPixels,
            aspectRation:ratio,
        });

        $("#abs1 > .canvas-title").css({width:canvasWidthInPixels+'px'});
        $("#abs2 > .canvas-title").css({width:canvasWidthInPixels+'px'});
        this.setState({
            canvas:this.canvas
        })
        this.canvas1 = new fabric.Canvas('back', {
            preserveObjectStacking: true,
            width: canvasWidthInPixels,
            height: canvasHeightInPixels,
            aspectRation:ratio,
        });
        this.setState({
            canvas1:this.canvas1
        })
        // this.state.canvasObj.push(this.canvas)
        // this.state.canvasObj.push(this.canvas1)
        this.props.addCanvasInCanvasLIst({side:'front', canvas:this.canvas})
        this.props.addCanvasInCanvasLIst({side:'back', canvas:this.canvas1})
        //for canvas history save - undo / redo
        this.canvas.state = [];
        this.canvas.index = 0;
        this.canvas.stateaction = true;
        this.canvas1.state = [];
        this.canvas1.index = 0;
        this.canvas1.stateaction = true;
        initCenteringGuidelines(this.canvas);
        initAligningGuidelines(this.canvas);
        initCenteringGuidelines(this.canvas1);
        initAligningGuidelines(this.canvas1);
        this.initCanvasEvents();
        this.canvasSettings(userId);
        // remove canvas image
        //this.setcanvasBG(this.props.state.defaultbg);
        //this updates the props also
        this.setState({
            displaybgColorPicker: false
        })
        this.props.updateCanvas(this.canvas);
        this.props.updateCanvases('front');
        //this.props.updateCanvas(this.canvas1);

        var ml =  "-" + $("#temp").width() / 2;

        this.setState({
            canvasOuterMargin:ml
        })
    }
    regularPolygonPoints(sideCount, radius) {
        var sweep = Math.PI * 2 / sideCount;
        var cx = radius;
        var cy = radius;
        var points = [];
        for (var i = 0; i < sideCount; i++) {
            var x = cx + radius * Math.cos(i * sweep);
            var y = cy + radius * Math.sin(i * sweep);
            points.push({ x: x, y: y });
        }
        return (points);
    }
    canvasSettings = (id) => {

        this.canvas.renderAll();

        const headers = {
            'Content-Type': 'application/json;charset=UTF-8',

        };
        //var lthis = this;
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}/userSettings?id=${id}`
        axios.get(apiUrl, {
            mode: 'cors',
            headers: headers,
        }

        ).then((response) => {
            var settings = JSON.parse(response.data.data[0].data);
            fabric.Object.prototype.set({
                    transparentCorners: false,
                    cornerColor: '#1babeb',
                    cornerStrokeColor: '#1babeb',
                    borderColor: '#1babeb',
                    cornerSize: 10,
                    padding: 5,
                    cornerStyle: 'circle',
            });
            this.canvas.loadFromJSON(settings, this.canvas.renderAll.bind(this.canvas), function(o, object) {

            fabric.log(o, object);
            });
            saveCanvasState(this.canvas);
                //this.canvas.setActiveObject(text);
                selectObject(this.canvas);
                this.canvas.renderAll();
        })
        .catch((err) => { });
    }
    plus() {
        //$("#abs1").css({"zoom": parseInt($("#my-range").val())+10 +'%'});
        //$("#abs2").css({"zoom": parseInt($("#my-range").val())+10 +'%'});
    }
    minus() {
        //$("#abs1").setZoom( +this.value );
        //$("#abs2").setZoom( +this.value );
        // $("#abs1").css({"zoom": parseInt($("#my-range").val())-10 +'%'});
        // $("#abs2").css({"zoom": parseInt($("#my-range").val())-10 +'%'});
    }
    map__SetZoom() {
        const zoomLevel = $("#my-range").val()
        const zoomValue = zoomLevel/100;
        this.setState({
            zoomLevel
        })
        const currentZoomLevel = this.state.canvas.getZoom();
        const initCanvasHeight = parseInt(this.state.initCanvasHeight)
        const initCanvasWidth = parseInt(this.state.initCanvasWidth)
        let newZoomLevel, newCanvasHeight, newCanvasWidth;
        newZoomLevel = this.state.initZoomLevel * zoomValue
        newCanvasHeight = parseInt(initCanvasHeight * zoomValue)
        newCanvasWidth = parseInt(initCanvasWidth * zoomValue)
        //front Canvas caculation
        let frontScaleXFactor = (newCanvasWidth+5) / this.state.canvas.backgroundImage.width
        let frontScaleYFactor = (newCanvasHeight+5) / this.state.canvas.backgroundImage.height
        this.state.canvas.backgroundImage.scaleX = frontScaleXFactor
        this.state.canvas.backgroundImage.scaleY = frontScaleYFactor
        this.state.canvas.setHeight(newCanvasHeight);
        this.state.canvas.setWidth(newCanvasWidth);

        //Back Canvas Caculation
        let backScaleXFactor = (newCanvasWidth+5) / this.state.canvas1.backgroundImage.width
        let backScaleYFactor = (newCanvasHeight+5) / this.state.canvas1.backgroundImage.height
        this.state.canvas1.backgroundImage.scaleX = backScaleXFactor
        this.state.canvas1.backgroundImage.scaleY = backScaleYFactor
        this.state.canvas1.setHeight(newCanvasHeight);
        this.state.canvas1.setWidth(newCanvasWidth);
        const parentHeight = newCanvasHeight + 60;

        $("#abs1").css({width:newCanvasWidth+'px', height:newCanvasHeight+'px'});
        $("#abs2").css({width:newCanvasWidth+'px', height:newCanvasHeight+'px'});
        $("#temp").css({ height:parentHeight+'px'});
        $("#abs1 > .canvas-title").css({width:newCanvasWidth+'px'});
        $("#abs2 > .canvas-title").css({width:newCanvasWidth+'px'});
        this.state.canvas.renderAll()
        this.state.canvas1.renderAll()
     }
    initCanvasEvents() {
       const {updateCanvases}=this.props
        //var i = 1, j = 1;
        var lthis = this;
        $(".canvas-area").click(function () {
            lthis.canvas.discardActiveObject();
            lthis.canvas.renderAll();
        });
        $('.canvas-container').click(function (e) {
            e.stopPropagation();
        });
        document.querySelector("#my-range").addEventListener("input", this.map__SetZoom);
        fabric.util.addListener(this.canvas.upperCanvasEl, 'click', function (e) {
        $("#abs1").css({"opacity": 1});
        $("#abs2").css({"opacity": 0.2});
        const activeobjs = [];
        activeobjs.push("front")
        updateCanvases("front");
            // if (lthis.state.subtarget) {
            //     selectObject(lthis.canvas, lthis.state.subtarget);
            //     lthis.setState({
            //         subtarget: null
            //     })
            // }
        });
        fabric.util.addListener(this.canvas1.upperCanvasEl, 'click', function (e) {
        $("#abs2").css({"background-color": "#fff"});
        $("#abs2").css({"opacity": 1});
        $("#abs1").css({"opacity": 0.2});
        const activeobjs = [];
        activeobjs.push("back")

        updateCanvases("back");

        //     if (lthis.state.subtarget) {
        //         selectObject(lthis.canvas1, lthis.state.subtarget);
        //         lthis.setState({
        //             subtarget: null
        //         })
        //     }
        });
    //     this.canvas.on("text:editing:entered", function(e){

    //         var activeObject = lthis.canvas.getActiveObject();



    //     // canvas.on('text:editing:entered', (textObject) => {
    //         // alert('am edit')
    //     // var activeObject = textObject.target
    //      let txt = activeObject.text

    //     activeObject.text="\u2022"+""

    //         lthis.canvas.add(activeObject);
    // lthis.canvas.setActiveObject(activeObject);
    // selectObject(lthis.canvas);
    // lthis.canvas.renderAll();
    //     });


        this.canvas.on({
            'mouse:down': (e) => {
                if (e.subTargets && e.subTargets[0]) {
                    lthis.setState({
                        subtarget: e.subTargets[0]
                    })
                }
            },

            'object:moving': (e) => {
                //Optimization: No need to updatestate on moving
                //lthis.updateState(e);
                if (this.props.state.isOverlap) {
                    lthis.avoidOverlap(e);
                }
                if (this.props.state.isSnap) {
                    e.target.set({
                        left: Math.round(e.target.left / this.props.state.gridsize) * this.props.state.gridsize,
                        top: Math.round(e.target.top / this.props.state.gridsize) * this.props.state.gridsize
                    });
                }
            },
            'object:added': (e) => {
                localStorage.setItem('objId', e.target.objId);
                lthis.updateState(e);
                saveCanvasState(lthis.canvas);

            },

            'object:modified': (e) => {

                localStorage.setItem('objId', e.target.objId);
                if (e.subTargets) {
                    selectObject(lthis.canvas, e.subTargets[0]);
                } else
                    selectObject(lthis.canvas);

            },
            'object:selected': (e) => {
                localStorage.setItem('objId', e.target.objId);
                lthis.updateState(e);
            },
            'object:scaling': (e) => {
                localStorage.setItem('objId', e.target.objId);
                //localStorage.setItem('content', a);
                lthis.updateState(e);

            },
            'selection:created': (e) => {
                localStorage.setItem('objId', e.target.objId);
                lthis.updateState();
                if (e.subTargets) {
                    selectObject(lthis.canvas, e.subTargets[0]);
                } else
                    selectObject(lthis.canvas);
            },
            'selection:updated': (e) => {
                localStorage.setItem('objId', e.target.objId);
                lthis.updateState();
                selectObject(lthis.canvas);
                //let object = lthis.canvas.getActiveObject();
            },
            'selection:cleared': (e) => {


                if (e.subTargets) {
                    selectObject(lthis.canvas, e.subTargets[0]);
                } else
                    selectObject(lthis.canvas);

            },
            'selection:added': (e) => { //alert('selection added')
            },
        });
         this.canvas1.on({
            'mouse:down': (e) => {
                if (e.subTargets && e.subTargets[0]) {
                    lthis.setState({
                        subtarget: e.subTargets[0]
                    })
                }
            },

            'object:moving': (e) => {

                lthis.updateState(e);
                if (this.props.state.isOverlap) {
                    lthis.avoidOverlap(e);
                }
                if (this.props.state.isSnap) {
                    e.target.set({
                        left: Math.round(e.target.left / this.props.state.gridsize) * this.props.state.gridsize,
                        top: Math.round(e.target.top / this.props.state.gridsize) * this.props.state.gridsize
                    });
                }
            },
            'object:added': (e) => {
                localStorage.setItem('objId', e.target.objId);
                lthis.updateState(e);
                saveCanvasState(lthis.canvas1);

            },

            'object:modified': (e) => {
                console.log("obj modify")
                console.log(e.target)
                localStorage.setItem('objId', e.target.objId);
                if (e.subTargets) {
                    selectObject(lthis.canvas1, e.subTargets[0]);
                } else
                    selectObject(lthis.canvas1);

            },
            'object:selected': (e) => {
                localStorage.setItem('objId', e.target.objId);
                lthis.updateState(e);
            },
            'object:scaling': (e) => {
                localStorage.setItem('objId', e.target.objId);
                lthis.updateState(e);

            },
            'selection:created': (e) => {
                localStorage.setItem('objId', e.target.objId);
                lthis.updateState();
                if (e.subTargets) {
                    selectObject(lthis.canvas, e.subTargets[0]);
                } else
                    selectObject(lthis.canvas);


            },
            'selection:updated': (e) => {
                localStorage.setItem('objId', e.target.objId);
                lthis.updateState();
                selectObject(lthis.canvas);
                //let object = lthis.canvas.getActiveObject();
            },
            'selection:cleared': (e) => {


                if (e.subTargets) {
                    selectObject(lthis.canvas, e.subTargets[0]);
                } else
                    selectObject(lthis.canvas);

            },
            'selection:added': (e) => { //alert('selection added')
            },
        });

    }

    avoidOverlap = (e) => {
        var snap = 20;
        var lthis = this;
        // Sets corner position coordinates based on current angle, width and height
        e.target.setCoords();
        // Don't allow objects off the canvas
        if (e.target.get('left') < snap) {
            e.target.set({
                left: 0
            });
        }
        if (e.target.get('top') < snap) {
            e.target.set({
                top: 0
            });
        }
        if ((e.target.get('width') + e.target.get('left')) > (this.canvasWidth - snap)) {
            e.target.set({
                left: this.canvasWidth - e.target.get('width')
            });
        }
        if ((e.target.get('height') + e.target.get('top')) > (this.canvasHeight - snap)) {
            e.target.set({
                top: this.canvasHeight - e.target.get('height')
            });
        }

        this.canvas.forEachObject(function (obj) {
            if (obj === e.target) return;
            // If objects intersect
            if (e.target.isContainedWithinObject(obj) || e.target.intersectsWithObject(obj) || obj.isContainedWithinObject(e.target)) {
                var distX = ((obj.get('left') + obj.get('width')) / 2) - ((e.target.get('left') + e.target.get('width')) / 2);
                var distY = ((obj.get('top') + obj.get('height')) / 2) - ((e.target.get('top') + e.target.get('height')) / 2);
                // Set new position
                lthis.findNewPos(distX, distY, e.target, obj);
            }
            // Snap objects to each other horizontally
            // If bottom points are on same Y axis
            if (Math.abs((e.target.get('top') + e.target.get('height')) - (obj.get('top') + obj.get('height'))) < snap) {
                // Snap target BL to object BR
                if (Math.abs(e.target.get('left') - (obj.get('left') + obj.get('width'))) < snap) {
                    e.target.set({
                        left: obj.get('left') + obj.get('width')
                    });
                    e.target.set({
                        top: obj.get('left') + obj.get('width')
                    });
                }
                // Snap target BR to object BL
                if (Math.abs((e.target.get('left') + e.target.get('width')) - obj.get('left')) < snap) {
                    e.target.set({
                        left: obj.get('left') - e.target.get('width')
                    });
                    e.target.set({
                        top: obj.get('top') + obj.get('height') - e.target.get('height')
                    });
                }
            }
            // If top points are on same Y axis
            if (Math.abs(e.target.get('top') - obj.get('top')) < snap) {
                // Snap target TL to object TR
                if (Math.abs(e.target.get('left') - (obj.get('left') + obj.get('width'))) < snap) {
                    e.target.set({
                        left: obj.get('left') + obj.get('width')
                    });
                    e.target.set({
                        top: obj.get('top')
                    });
                }
                // Snap target TR to object TL
                if (Math.abs((e.target.get('left') + e.target.get('width')) - obj.get('left')) < snap) {
                    e.target.set({
                        left: obj.get('left') + obj.get('width')
                    });
                    e.target.set({
                        top: obj.get('top')
                    });
                }
            }
            // Snap objects to each other vertically
            // If right points are on same X axis
            if (Math.abs((e.target.get('left') + e.target.get('width')) - (obj.get('left') + obj.get('width'))) < snap) {
                // Snap target TR to object BR
                if (Math.abs(e.target.get('top') - (obj.get('top') + obj.get('height'))) < snap) {
                    e.target.set({
                        left: obj.get('left') + obj.get('width') - e.target.get('width')
                    });
                    e.target.set({
                        top: obj.get('top') + obj.get('height')
                    });
                }
                // Snap target BR to object TR
                if (Math.abs((e.target.get('top') + e.target.get('height')) - obj.get('top')) < snap) {
                    e.target.set({
                        left: obj.get('left') + obj.get('width') - e.target.get('width')
                    });
                    e.target.set({
                        top: obj.get('top') - e.target.get('height')
                    });
                }
            }
            // If left points are on same X axis
            if (Math.abs(e.target.get('left') - obj.get('left')) < snap) {
                // Snap target TL to object BL
                if (Math.abs(e.target.get('top') - (obj.get('top') + obj.get('height'))) < snap) {
                    e.target.set({
                        left: obj.get('left')
                    });
                    e.target.set({
                        top: obj.get('top') + obj.get('height')
                    });
                }
                // Snap target BL to object TL
                if (Math.abs((e.target.get('top') + e.target.get('height')) - obj.get('top')) < snap) {
                    e.target.set({
                        left: obj.get('left')
                    });
                    e.target.set({
                        top: obj.get('top') + obj.get('height')
                    });
                }
            }
        });
    }

    findNewPos = (distX, distY, target, obj) => {
        // See whether to focus on X or Y axis
        if (Math.abs(distX) > Math.abs(distY)) {
            if (distX > 0) {
                target.set({
                    left: obj.get('left') - target.get('width')
                });
            } else {
                target.set({
                    left: obj.get('left') + obj.get('width')
                });
            }
        } else {
            if (distY > 0) {
                target.set({
                    top: obj.get('top') - target.get('height')
                });
            } else {
                target.set({
                    top: obj.get('top') + obj.get('height')
                });
            }
        }
    }

    deleteCanvasBg = () => {
        this.canvas.backgroundColor = '';
        this.canvas.renderAll();
        var objects = this.canvas.getObjects().filter(function (o) {
            return o.bg === true;
        });
        for (var i = 0; i < objects.length; i++) {
            this.canvas.remove(objects[i]);
        }
        this.canvas.bgsrc = "";
        this.canvas.bgcolor = "";
    }

    setcanvasBG = (result) => {
        var bgsrc = result;
        if (result && result.url) bgsrc = result.url;
        if (bgsrc) {
            this.deleteCanvasBg();
            fabric.Image.fromURL(bgsrc, (bg) => {
                var canvasAspect = this.canvas.width / this.canvas.height;
                var imgAspect = bg.width / bg.height;
                var scaleFactor;
                if (canvasAspect >= imgAspect) {
                    scaleFactor = this.canvas.width / bg.width * 1;
                } else {
                    scaleFactor = this.canvas.height / bg.height * 1;
                }
                bg.set({
                    originX: 'center',
                    originY: 'center',
                    opacity: 1,
                    selectable: false,
                    hasBorders: false,
                    hasControls: false,
                    hasCorners: false,
                    left: this.canvas.width / 2,
                    top: this.canvas.height / 2,
                    scaleX: scaleFactor,
                    scaleY: scaleFactor,
                    strokeWidth: 0
                });
                this.canvas.add(bg);
                this.canvas.sendToBack(bg);
                bg.bg = true;
                this.canvas.bgsrc = bgsrc;
            });
        }
    }

    bgpickerOpen = () => {
        this.setState({
            displaybgColorPicker: !this.state.displaybgColorPicker
        })
    };

    bgpickerClose = () => {
        this.setState({
            displaybgColorPicker: false
        })
    };

    render() {
        // const displayzoomLevel = parseInt(this.state.zoomLevel* (this.canvas?.aspectRation || 1))
        const displayzoomLevel = parseInt(this.state.zoomLevel)
        return (
             
            <div id="main-area" ref={this.mainCanvasContainer} className="main-area" style={{position:"absolute",backgroundColor:"#4F4F4F",width:"100%"}}>
                <div id="temp" style={{position:"absolute",top:"40px", minWidth:"max-content",marginLeft:"5%"}}>
                    <div id="abs1" className="canvasfrontback" >
                        <div className="fronttxt canvas-title">Front</div>
                        <canvas id='front'></canvas>
                    </div>
                    <div id="abs2"  className="canvasfrontback" >
                        <div className="backtxt canvas-title">Back</div>
                        <canvas id='back'></canvas>
                    </div>
                </div>
                {$(window).width()>1100?
                   <div className="largedevice" style={{position:"absolute", marginTop:"50%", marginLeft:"70%"}}>
                   {/* <button onClick={this.minus}>-</button> */}
                   <input id="my-range" type="range" min="25" max="400" step="1" defaultValue="100"></input>
                   <span className="zoom-value">{displayzoomLevel}%</span>
                   {/* <button onChange={this.plus}>+</button> */}
                   </div>:   <div className="mobilenav"  style={{position:"absolute",marginBottom:"50%"}}>
                {/* <button onClick={this.minus}>-</button> */}
                <input id="my-range" type="range" min="25" max="400" step="1" defaultValue="100"></input>
                <span className="zoom-value">{displayzoomLevel}%</span>
                {/* <button onChange={this.plus}>+</button> */}
                </div>
            }
             
             
         
            {/* <div className='largedevice' style={{position:"absolute", top:"105%", right:"0"}}>
                <input id="my-range" type="range" min="25" max="400" step="1" defaultValue="100"></input>
                <span className="zoom-value">{displayzoomLevel}%</span>
            </div> */}
            </div>
            
        );
    }
}


export default FabricCanvas;
