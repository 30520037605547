import React, { Component } from "react";
import { fabric } from "fabric";
import { SketchPicker } from "react-color";
import { Row, Col, Container, Form, Input } from "reactstrap";
import {Nav, NavItem, NavLink, Card, Button as Bbutton, CardTitle, CardText } from "reactstrap";
import classnames from 'classnames';


import { TabPanel } from "react-web-tabs";
import { client } from "filestack-react";
import { TabContent, TabPane } from "reactstrap";

//import Popup from 'reactjs-popup';
import { DEFAULT_FONT_PROPS, templates, DPI_THRESHOLD, IMAGE_MAX_WIDTH, productTypes, VERSES, HOST_SITE } from "../constants";
import {getDpi} from './Helpers';

import {
    unique,
    saveCanvasState,
    selectObject,
    //getCanvasBySide,
    deleteCanvasBg,
    setcanvasBG
} from "./Helpers";
import trashimg from "../images/trash.png";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import diamond from "../images/elements/diamond.svg";
//import mg from '../images/animals_mascots/angels/BAS_Angels_  A w wings & halo black R1-1.gif'
//import classnames from 'classnames';
import "./modal.css";
import "./paginate.css";
//import ReactPaginate from 'react-paginate';
import config from "../../config";
import axios from "axios";
import $ from "jquery";
import jsPDF from "jspdf";
//import html2canvas from "html2canvas";
import ListGroup from "reactstrap/lib/ListGroup";

import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';



const INIT_SOLID_COLORS = [
    "#d0021b",
    "#f5a623",
    "#f8e71c",
    "#8b572a",
    "#b8e986",
    "#417505",
    "#4a90e2",
    "#50e3ca",
    "#000000",
    "#ffffff",
];

// const INIT_PATTERN_IMAGES = [
//     require('../images/img/pattern1.jpg'),
//     require('../images/img/pattern2.jpg'),
//     require('../images/img/pattern3.jpg'),
//     require('../images/img/pattern4.jpg'),
//     require('../images/img/pattern5.jpg'),
//     require('../images/img/pattern6.jpg'),
//     require('../images/img/pattern7.jpg'),
//     require('../images/img/pattern8.jpg'),
//     require('../images/img/pattern9.jpg')
// ]
/*const INIT_ELEMENT_SHAPES = [
    require('../images/elements/circle.svg'),
    require('../images/elements/star.svg'),
    require('../images/elements/square.svg'),
    require('../images/elements/diamond.svg'),
    require('../images/elements/hexagon.svg'),

]

const INIT_ELEMENT_CLIPARTS = [
    require('../images/animals_mascots/angels/BAS_Angels_  A w wings & halo black R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_  A w wings & halo R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ A against black circle R -1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ A w geometric wings001 R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ A w geometric wings002 R1-1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ A w geometric wings002 R1-2.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ A w geometric wings003 R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ Angels script black R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ Angels script w wings & halo R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ block letters black w geometric wings clear R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_ block letters clear w geometric wings black R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_angel icon praying R-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_angelfish R1-1.gif'),
    require('../images/animals_mascots/angels/BAS_Angels_cross w black wings & halo R1-1.gif'),
]*/

class LeftPanel extends Component {
    state = {
        displaybgColorPicker: false,
        displaygrad1ColorPicker: false,
        displaygrad2ColorPicker: false,
        canvasScale: 1,
        SCALE_FACTOR: 1.2,
        bgcolArray: [],
        backgroundcolor: "",
        grad1color: "black",
        grad2color: "black",
        apiImg: [],
        clipartData: [],
        clipartImages: [],
        subcat: "",
        page: 1,
        searchkey: "sport",
        activeTab: "1",
        imgactiveTab: "1",
        unsplashImg: [],
        unsplashsearchkey: "woods",
        pagenum: 1,
        isOpen: false,
        isDPIOpen: false,
        isLargeImage: false,
        uploadImageWidth: null,
        imageDPI:null,
        data: [],
        offset: 0,
        perPage: 2,
        currentPage: 0,
        templatesValues : null,
        showPopover: []
    };

    constructor(props) {
        super(props);


        // this.iScrollRef = React.createRef();
        this.imgScrollRef = React.createRef();
        this.popoverRef = React.createRef();
        this.shapeUpload = this.shapeUpload.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
        this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);

        //this.addBulletText = this.addBulletText.bind(this);
    }
    handleOnMouseEnter = (index) => {
        let arrStr=[];
        arrStr[index] = true
        this.setState({
            showPopover: arrStr
        });
    }
    handleOnMouseLeave = ( index) => {
        let arrStr=[];
        arrStr[index] = false
        this.setState({
            showPopover: arrStr
        });
    }
    handlePageClick = (e) => {
        const selectedPage = e.selected;
        const offset = selectedPage * this.state.perPage;

        this.setState(
            {
                currentPage: selectedPage,
                offset: offset,
            },
            () => {
                this.getClipart("1", "1");
            }
        );
    };
    getClipart = (type1, type2) => {
        this.setState({ subcat: type2 });
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
        };
        //const {clipartImages } = this.state;
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}/clipart?cat=${type1}&subcat=${type2}`;
        axios
            .get(apiUrl, {
                mode: "cors",
                headers: headers,
            })
            .then((response) => {
                //     const data = res.data.data;
                //      const slice = data.slice(this.state.offset, this.state.offset + this.state.perPage)

                // console.log(slice);
                //     const postData = slice.map(pd => <React.Fragment>
                //         <p>{pd.title}</p>
                //         <img src={pd.thumbnailUrl} alt=""/>
                //     </React.Fragment>)

                //     this.setState({
                //         pageCount: Math.ceil(data.length / this.state.perPage),

                //         postData
                //     })
                if (response.data.data) {
                    const data = [];
                    let clipImages = response.data.data;

                    for (var key in clipImages) {
                        data.push(clipImages[key]);
                    }
                    this.setState({
                        clipartImages: data,
                    });
                }
            });
    };
    openModal = () =>
        this.setState({
            isOpen: true,
        });
    closeModal = () => {
        this.setState({
            isOpen: false,
        });
    };
    openDPIModal = () =>
        this.setState({
            isDPIOpen: true,
            isLargeImage: true
        });
    closeDPIModal = () => {
        this.setState({
            isDPIOpen: false,
            isLargeImage: false
        });
    };

    componentDidMount() {
        this.clipartGallery();
        this.getClipart("1", "1");
        let bgcolArray = localStorage.getItem("bgcolors");
        if (bgcolArray) {
            bgcolArray = JSON.parse(bgcolArray);
            this.setState({
                bgcolArray: bgcolArray,
            });
        }
        this.imgScrollRef.current.addEventListener("scroll", () => {
            if (
                this.imgScrollRef.scrollTop + this.imgScrollRef.clientHeight >=
                this.imgScrollRef.scrollHeight
            ) {
                this.incermentpage();
            }
        });

        // // REMOVE ME:
        // setTimeout(() => {
        //     this.setBGcolor("#ffffff");
        // }, 100);
        const productNames = Object.keys(templates)

        if(productNames.includes(this.props.templateKey)){
            setcanvasBG(templates[this.props.templateKey], this.props.allCanvasList )
        }
        const templateArray = Object.values(templates)
        const templateArray2D = templateArray.reduce((acc, curr, index) => {
            if (index && (index + 1) % 2 === 0) {
              acc.push([templateArray[index - 1], templateArray[index]]);
            }
            return acc;
          }, [] );
        this.setState({
            templatesValues: templateArray2D
        })


    }
    componentDidUpdate(){
        /** Image Gallery masonary */
        const Masonry = require("masonry-layout");
        const imgloaded = require("imagesloaded");
        const container = document.querySelector("#uploadedImgDiv");
        const gridElement = document.querySelector(".grid");
        var imgLoad = imgloaded(container);
        imgLoad.on("always", onImageLoad);
        function onImageLoad() {
            var msnry = new Masonry( gridElement, {
                itemSelector: '.grid-item',
                gutter: 5,
                fitWidth: true
            });
        }
    }
    clipartGallery = () => {
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
        };

        //const {clipartgallery } = this.state;
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}/clipartgallery`;
        axios
            .get(apiUrl, {
                mode: "cors",
                headers: headers,
            })
            .then((response) => {
                if (response.data.data) {
                    this.setState({
                        clipartData: response.data.data,
                    });
                }
            })
            .catch((err) => {});
    };



    /* addBulletText = () => {
        var canvas = this.props.canvas;
        const fontSize = 10;
        const text = `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Integer nec odio.
    Praesent libero. Sed cursus ante dapibus diam.`;
        const t0 = new fabric.Textbox(text, {
            name: "list",
            fontFamily: "sans-serif",
            fontSize: fontSize,
            lineHeight: 1.40,
            left: 75,
            top: 50,
            width: 350,
            objectCaching: false,
            isWrapping: false,
            listType: "bullet",
            listBullet: "✱",
            listCounter: 0
        });
        console.log('text object t0 ', t0)
        const renderTextLine = function (method, ctx, line, left, top, lineIndex) {
            const style0 = this.getCompleteStyleDeclaration(lineIndex, 0),
                bullet = this.listType === "bullet"
                        ? [this.listBullet]
                        : [this.listCounter + 1 + "."],
                bulletLeft = left - style0.fontSize - 2;
            console.log('bullet ', style0, bullet )
            console.log('bulletLeft ', bulletLeft , this.isWrapping)
            if (line.length) {
                if (!this.isWrapping) {
                    // render the bullet
                    this._renderChars(method, ctx, bullet, bulletLeft, top, lineIndex);
                    this.isWrapping = !this.isEndOfWrapping(lineIndex);
                    if (!this.isWrapping) this.listCounter++;
                } else if (this.isEndOfWrapping(lineIndex)) {
                    this.isWrapping = false;
                    this.listCounter++;
                }
            }
            if (lineIndex === this.textLines.length - 1) {
                this.isWrapping = false;
                this.listCounter = 0;
            }
            // render the text line
            console.log('line ', line)
            this._renderChars(method, ctx, line, left, top, lineIndex);
        }
        t0._renderTextLine = renderTextLine;
        canvas.add(t0);
        canvas.renderAll();
    } */
    //Common method to addtext
    addText = (type, text, isVerse) => {
        isVerse = !!isVerse
        var canvas = this.props.canvas;
        let fontSize = DEFAULT_FONT_PROPS.text_size;
        if (type === "heading") {
            fontSize = DEFAULT_FONT_PROPS.heading_size;
        } else if (type === "title") {
            fontSize = DEFAULT_FONT_PROPS.title_size;
        }
        let top = 100;
        let left = 100;

        const textProperties = {
            id: Math.floor(Math.random() * 1000),
            fontFamily: DEFAULT_FONT_PROPS.font,
            left: left,
            top: top,
            type: "text",
            fontSize,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#000000",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle:"",
            lineHeight:1
        };

        var textObj = new fabric.IText(text, textProperties);
        //text._renderTextLine = this.renderTextLine;
        canvas.add(textObj);
        canvas.setActiveObject(textObj);
        canvas.centerObject(textObj);
        selectObject(canvas);
        canvas.renderAll();
        //this.addBulletText();
    };
    addVerse = (title, textStr) => {
        var canvas = this.props.canvas;
        //this.addText('title', title, true)
        let fontSize = DEFAULT_FONT_PROPS.title_size;
        textStr = `${title}\n\n${textStr}`

        const textProperties = {
            id: Math.floor(Math.random() * 1000),
            fontFamily: DEFAULT_FONT_PROPS.font,
            left: 100,
            top: 100,
            type: "text",
            fontSize,
            strokeWidth: 1,
            strokeLineJoin: "bevil",
            fill: "#000000",
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: "#1babeb",
            cornerStrokeColor: "#1babeb",
            borderColor: "#1babeb",
            cornerSize: 5,
            padding: 5,
            cornerStyle: "circle",
            listStyle:"",
            lineHeight:1,
            textAlign:'center'
        };
        var text = new fabric.IText(textStr, textProperties);
        canvas.add(text);
        canvas.setActiveObject(text);
        canvas.centerObject(text);
        selectObject(canvas);
        canvas.renderAll();
    };
    /* addHeadingtxt = () => {
        var canvas = this.props.canvas;
        var canvasObj = this.props.canvasObj;
        if(canvasObj[2]==undefined || canvasObj[canvasObj.length - 1] === 'front')
        canvas=canvasObj[0]
        else
        canvas=canvasObj[1]
        var text = new fabric.IText('Add Heading', {
            id:Math.floor(Math.random() * 1000),
            fontFamily: 'Montserrat',
            left: 100,
            top: 100,
            type: 'text',
            fontSize: 24,
            //width: 100,
            //height: 100,
            strokeWidth: 1,
            strokeLineJoin: 'bevil',
            fill: '#000000',
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: '#1babeb',
            cornerStrokeColor: '#1babeb',
            borderColor: '#1babeb',
            cornerSize: 5,
            padding: 5,
            cornerStyle: 'circle'
        });

        canvas.add(text);
        canvas.setActiveObject(text);
        canvas.centerObject(text);
        selectObject(canvas);
        canvas.renderAll();
    } */
    // clearText=(e)=> {
    //     var canvas = this.props.canvas;
    //     if (e.target.type === "i-text") {
    //       if (e.target.text === "Add Heading") {
    //         e.target.text = "";
    //         e.target.hiddenTextarea.value = '';  // NEW
    //         canvas.renderAll();
    //       };
    //     }
    //   }

    /* addSubheadingtxt = () => {
        var canvas = this.props.canvas;
        var canvasObj = this.props.canvasObj;
        if(canvasObj[2]==undefined || canvasObj[canvasObj.length - 1] === 'front')
        canvas=canvasObj[0]
        else
        canvas=canvasObj[1]
        var text = new fabric.IText('Add Title', {
            id:Math.floor(Math.random() * 1000),
            fontFamily: 'Montserrat',
            left: 100,
            top: 100,
            type: 'text',
            fontSize: 12,
            width: 100,
            height: 100,
            strokeWidth: 1,
            strokeLineJoin: 'bevil',
            fill: '#000000',
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: '#1babeb',
            cornerStrokeColor: '#1babeb',
            borderColor: '#1babeb',
            cornerSize: 8,
            padding: 5,
            cornerStyle: 'circle'
        });
        canvas.add(text);
        canvas.setActiveObject(text);
        canvas.centerObject(text);
        selectObject(canvas);
        canvas.renderAll();
    } */

    /* addText = () => {
        var canvas = this.props.canvas;
        var canvasObj = this.props.canvasObj;
        if(canvasObj[2]==undefined || canvasObj[canvasObj.length - 1] === 'front')
        canvas=canvasObj[0]
        else
        canvas=canvasObj[1]
        var text = new fabric.IText('Add Description', {
            id:Math.floor(Math.random() * 1000),
            fontFamily: 'Montserrat',
            left: 100,
            top: 100,
            type: 'text',
            fontSize: 18,
            width: 200,
            height: 200,
            strokeWidth: 1,
            strokeLineJoin: 'bevil',
            fill: '#000000',
            subTargetCheck: true,
            transparentCorners: false,
            cornerColor: '#1babeb',
            cornerStrokeColor: '#1babeb',
            borderColor: '#1babeb',
            cornerSize: 8,
            padding: 5,
            cornerStyle: 'circle'
        });
        canvas.add(text);
        canvas.setActiveObject(text);
        canvas.centerObject(text);
        selectObject(canvas);
        canvas.renderAll();
    } */


    setCanvasFill = (bgcolor) => {
        var canvas = this.props.canvas;
        // var canvasObj = this.props.canvasObj;
        // if(canvasObj[2]==undefined || canvasObj[canvasObj.length - 1] === 'front')
        // canvas=canvasObj[0]
        // else
        // canvas=canvasObj[1]
        deleteCanvasBg(this.props.canvas);
        canvas.backgroundColor = bgcolor.hex;
        canvas.renderAll();
        this.setState({
            backgroundColor: bgcolor.hex,
        });
        saveCanvasState(canvas);
    };

    dynamicBGcolors = (bgcol) => {
        var bgcolArray = this.state.bgcolArray;
        bgcolArray.push(bgcol);
        bgcolArray = unique(bgcolArray);
        console.log(bgcolArray);
        this.setState({
            bgcolArray: bgcolArray,
        });
        this.setState({
            backgroundcolor: bgcol,
        });
        localStorage.setItem("bgcolors", JSON.stringify(bgcolArray));
    };

    // showUploadPopup = () => {
    //     const options = {
    //         accept: 'image/*',
    //         //fromSources: ['local_file_system'],
    //         maxSize: 1024 * 1024,
    //         maxFiles: 1,
    //         onFileUploadFinished: this.setcanvasBG
    //     }
    //     const filestack = client.init('A002ziWzKRKms2ktqgtQmz', options);
    //     const picker = filestack.picker(options);
    //     picker.open();
    // }

    shapeUpload = async (event) => {

        var props = this.props;
        var canvas = this.props.canvas;
        if (event.target.files.length > 0) {
            const _this = this
            $.each(event.target.files, function () {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        var img = new fabric.Image(image);
                        const imageWidth = img.width
                        if(imageWidth > IMAGE_MAX_WIDTH){
                            _this.setState({
                                uploadImageWidth:imageWidth,
                                isLargeImage: true
                            })
                            return false
                        }
                        //calculate image DPI
                        const canvasWidthInInch = parseFloat(_this.props.templateSize.width)
                        const imageDpi = getDpi(imageWidth, canvasWidthInInch)
                        _this.setState({
                            imageDPI:imageDpi
                        })
                        if(imageDpi < DPI_THRESHOLD){
                            _this.setState({
                                isDPIOpen: true
                            })
                        }
                        img.set({
                            id: Math.floor(Math.random() * 1000),
                            left: 100,
                            top: 100,
                            width: img.width,
                            height: img.height,
                            scale: 1,
                            scaleX: 0.2,
                            scaleY: 0.2,
                            strokeWidth: "",
                            strokeLineJoin: "bevil",
                            fill: "#6c757d",
                            subTargetCheck: true,
                            transparentCorners: false,
                            hasControls: true,
                            hasBorders: true,
                            cornerColor: "#1babeb",
                            cornerStrokeColor: "#1babeb",
                            borderColor: "#1babeb",
                            cornerSize: 8,
                            padding: 5,
                            cornerStyle: "circle",
                        });
                        canvas.add(img);
                        canvas.setActiveObject(img);
                        canvas.centerObject(img);
                        canvas.renderAll();
                    };
                };


                reader.readAsDataURL(this);
                let apiDynamicUrlAddress = config.default.api.host;
                const formData = new FormData();
                formData.append("file", this);
                formData.append("fileName", this.name);
                formData.append("userId", 1);
                // let apiUrl = `${apiDynamicUrlAddress}getAssests?type=logos&userId=${userId}`
                let apiUrl = `${apiDynamicUrlAddress}/upload`;
                axios
                    .post(apiUrl, formData)
                    .then((response) => {
                        props.imgGallery();
                    })
                    .catch((err) => {});
            });
        }
    };
    templateUpload = async (event) => {
        var canvas = this.props.canvas;
        if (event.target.files.length > 0) {
            //     $.each(event.target.files, function () {
            //         var reader = new FileReader();
            //         reader.onload = function (e) {
            //             var image = new Image();
            //             image.src = e.target.result;
            //             image.onload = function () {
            //                 var img = new fabric.Image(image);
            //                 img.set({
            //                 id:Math.floor(Math.random() * 1000),
            //                     left: 100,
            //                     top: 100,
            //                     width: 200,
            //                     height: 200,
            //                     strokeWidth: '',
            //                     strokeLineJoin: 'bevil',
            //                     fill: '#6c757d',
            //                     subTargetCheck: true,
            //                     transparentCorners: false,
            //                     cornerColor: '#1babeb',
            //                     cornerStrokeColor: '#1babeb',
            //                     borderColor: '#1babeb',
            //                     cornerSize: 10,
            //                     padding: 5,
            //                     cornerStyle: 'circle'
            //                 });
            //                 canvas.add(img);
            //                 canvas.setActiveObject(img);
            //                 canvas.renderAll();
            //             }
            //         };
            //         reader.readAsDataURL(this);
            //         let apiDynamicUrlAddress = config.default.api.host;
            // const formData = new FormData();
            // formData.append("file", this);
            // formData.append("fileName", this.name);
            // formData.append("userId", 1);
            // // let apiUrl = `${apiDynamicUrlAddress}getAssests?type=logos&userId=${userId}`
            // let apiUrl = `${apiDynamicUrlAddress}/upload`;
            // axios.post(apiUrl, formData).then((response) => {
            //     this.props.imgGallery();
            // })
            //     .catch((err) => {
            //     });
            //     });
        }
        // var reader = new FileReader();
        // reader.onload =function (event) {
        //     var image = new Image();

        //     image.src = event.target.result;
        //     image.onload = function () {
        //         var img = new fabric.Image(image);
        //         img.set({
        //         id:Math.floor(Math.random() * 1000),
        //             left: 100,
        //             top: 100,
        //             width: 200,
        //             height: 200,
        //             strokeWidth: '',
        //             strokeLineJoin: 'bevil',
        //             fill: '#6c757d',
        //             subTargetCheck: true,
        //             transparentCorners: false,
        //             cornerColor: '#1babeb',
        //             cornerStrokeColor: '#1babeb',
        //             borderColor: '#1babeb',
        //             cornerSize: 10,
        //             padding: 5,
        //             cornerStyle: 'circle'
        //         });
        //         canvas.add(img);
        //         canvas.setActiveObject(img);
        //         canvas.renderAll();
        //     }
        // }
        // reader.readAsDataURL(event.target.files[0]);
        var bgsrc = event.target.result;

        if (bgsrc) {
            deleteCanvasBg(this.props.canvas);
            fabric.Image.fromURL(bgsrc, (bg) => {
                var canvasAspect = canvas.width / canvas.height;
                var imgAspect = bg.width / bg.height;
                var scaleFactor;
                if (canvasAspect >= imgAspect) {
                    scaleFactor = (canvas.width / bg.width) * 1;
                } else {
                    scaleFactor = (canvas.height / bg.height) * 1;
                }
                bg.set({
                    originX: "center",
                    originY: "center",
                    opacity: 1,
                    selectable: false,
                    hasBorders: false,
                    hasControls: false,
                    hasCorners: false,
                    left: canvas.width / 2,
                    top: canvas.height / 2,
                    scaleX: scaleFactor,
                    scaleY: scaleFactor,
                    strokeWidth: 0,
                });
                canvas.add(bg);
                canvas.sendToBack(bg);
                bg.bg = true;
                canvas.bgsrc = bgsrc;
                saveCanvasState(canvas);
            });
        }
    };
    removeImg = (id) => {
        let apiDynamicUrlAddress = config.default.api.host;
        let apiUrl = `${apiDynamicUrlAddress}/removeImg?id=${id}`;
        axios
            .get(apiUrl, {
                crossDomain: true,
                enablePreflight: false,
            })
            .then((response) => {
                this.props.imgGallery();
            })
            .catch((err) => {});
    };
    readImage = (input) => {
        if (input.files) console.log(input.files[0]);
        var canvas = this.props.canvas;
        var ctx = canvas.getContext("2d");
        //if (!this.files || !this.files[0]) return;

        const FR = new FileReader();
        FR.addEventListener("load", (evt) => {
            const img = new Image();
            img.addEventListener("load", () => {
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                ctx.drawImage(img, 0, 0);
                ctx.globalCompositeOperation = "destination-in";
                ctx.beginPath();
                ctx.arc(75, 75, 50, 0, Math.PI * 2);
                //ctx.fillRect(25, 25, 100, 100);
                ctx.beginPath();
                ctx.moveTo(75, 40);
                ctx.bezierCurveTo(75, 37, 70, 25, 50, 25);
                ctx.bezierCurveTo(20, 25, 20, 62.5, 20, 62.5);
                ctx.bezierCurveTo(20, 80, 40, 102, 75, 120);
                ctx.bezierCurveTo(110, 102, 130, 80, 130, 62.5);
                ctx.bezierCurveTo(130, 62.5, 130, 25, 100, 25);
                ctx.bezierCurveTo(85, 25, 75, 37, 75, 40);
                ctx.fillStyle = "#0095DD";
                ctx.fill();
                // reset to default
                ctx.globalCompositeOperation = "source-over";
                // closePath is useless here
                //ctx.closePath();
            });
            img.src = evt.target.result;
        });
        FR.readAsDataURL(this.files[0]);
    };
    uploadIcon = () => {
        const options = {
            accept: "image/svg+xml",
            //fromSources: ['local_file_system'],
            maxSize: 1024 * 1024,
            maxFiles: 1,
            onFileUploadFinished: this.addSVG,
        };
        const filestack = client.init("A002ziWzKRKms2ktqgtQmz", options);
        const picker = filestack.picker(options);
        picker.open();
    };

    handleUploadError = (e) => {
        console.log(e);
    };
    regularPolygonPoints(sideCount, radius) {
        var sweep = (Math.PI * 2) / sideCount;
        var cx = radius;
        var cy = radius;
        var points = [];
        for (var i = 0; i < sideCount; i++) {
            var x = cx + radius * Math.cos(i * sweep);
            var y = cy + radius * Math.sin(i * sweep);
            points.push({ x: x, y: y });
        }
        return points;
    }
    starPolygonPoints(spikeCount, outerRadius, innerRadius) {
        //var rot = Math.PI / 2 * 3;
        var cx = outerRadius;
        var cy = outerRadius;
        var sweep = Math.PI / spikeCount;
        var points = [];
        var angle = 0;

        for (var i = 0; i < spikeCount; i++) {
            var x = cx + Math.cos(angle) * outerRadius;
            var y = cy + Math.sin(angle) * outerRadius;
            points.push({ x: x, y: y });
            angle += sweep;

            x = cx + Math.cos(angle) * innerRadius;
            y = cy + Math.sin(angle) * innerRadius;
            points.push({ x: x, y: y });
            angle += sweep;
        }
        return points;
    }
    fillImage = (flg) => {
        var canvas = this.props.canvas;
        // var canvasObj = this.props.canvasObj;
        // if(canvasObj[2]==undefined || canvasObj[canvasObj.length - 1] === 'front')
        // canvas=canvasObj[0]
        // else
        // canvas=canvasObj[1]
        var shape;
        var points;
        if (flg === 1) {
            shape = new fabric.Circle({
                id: Math.floor(Math.random() * 1000),
                radius: 70,
                left: 200,
                top: 100,
                width: 200,
                height: 200,
                strokeWidth: "",
                stroke: "",
                fill: "#6c757d",
                subTargetCheck: true,
                transparentCorners: false,
                cornerColor: "#1babeb",
                cornerStrokeColor: "#1babeb",
                borderColor: "#1babeb",
                cornerSize: 10,
                padding: 5,
                cornerStyle: "circle",
            });
        } else if (flg === 2) {
            shape = new fabric.Rect({
                id: Math.floor(Math.random() * 1000),
                left: 200,
                top: 100,
                width: 200,
                height: 200,
                fill: "#6c757d",
                subTargetCheck: true,
                transparentCorners: false,
                cornerColor: "#1babeb",
                cornerStrokeColor: "#1babeb",
                borderColor: "#1babeb",
                cornerSize: 10,
                padding: 5,
                cornerStyle: "circle",
            });
        } else if (flg === 3) {
            // make a star
            points = this.starPolygonPoints(5, 50, 25);
            shape = new fabric.Polygon(
                points,
                {
                    id: Math.floor(Math.random() * 1000),
                    left: 200,
                    top: 100,
                    width: 200,
                    height: 200,
                    strokeWidth: "",
                    strokeLineJoin: "bevil",
                    fill: "#6c757d",
                    subTargetCheck: true,
                    transparentCorners: false,
                    cornerColor: "#1babeb",
                    cornerStrokeColor: "#1babeb",
                    borderColor: "#1babeb",
                    cornerSize: 10,
                    padding: 5,
                    cornerStyle: "circle",
                },
                false
            );
        } else if (flg === 4) {
            shape = new fabric.Rect({
                id: Math.floor(Math.random() * 1000),
                left: 200,
                top: 100,
                width: 200,
                height: 200,
                angle: 45,
                fill: "#6c757d",
                subTargetCheck: true,
                transparentCorners: false,
                cornerColor: "#1babeb",
                cornerStrokeColor: "#1babeb",
                borderColor: "#1babeb",
                cornerSize: 10,
                padding: 5,
                cornerStyle: "circle",
            });
        } else if (flg === 5) {
            points = this.regularPolygonPoints(6, 30);

            shape = new fabric.Polygon(
                points,
                {
                    id: Math.floor(Math.random() * 1000),
                    left: 200,
                    top: 100,
                    width: 200,
                    height: 200,
                    strokeWidth: "",
                    strokeLineJoin: "bevil",
                    fill: "#6c757d",
                    subTargetCheck: true,
                    transparentCorners: false,
                    cornerColor: "#1babeb",
                    cornerStrokeColor: "#1babeb",
                    borderColor: "#1babeb",
                    cornerSize: 10,
                    padding: 5,
                    cornerStyle: "circle",
                },
                false
            );
        }

        canvas.add(shape);
        canvas.setActiveObject(shape);
        canvas.centerObject(shape);
        selectObject(canvas);
        canvas.renderAll();
    };
    addSVG = (result) => {
        var canvas = this.props.canvas;
        var svg = result;
        fabric.Image.fromURL(svg, (objects) => {
            //var loadedObject = fabric.util.groupSVGElements(objects);
            objects.set({
                id: Math.floor(Math.random() * 1000),
                scaleX: 300,
                scaleY: 300,
                opacity: 1,
                subTargetCheck: true,
                transparentCorners: false,
                cornerColor: "#1babeb",
                cornerStrokeColor: "#1babeb",
                borderColor: "#1babeb",
                cornerSize: 10,
                padding: 5,
                cornerStyle: "circle",
            });
            objects.src = svg;
            canvas.add(objects);
            canvas.setActiveObject(objects);
            objects.scaleToWidth(150);
            objects.hasRotatingPoint = true;
            saveCanvasState(canvas);
            selectObject(canvas);
            canvas.renderAll();
        });
    };

    // setcanvasBG = (result) => {
    //     deleteCanvasBg();
    //     const front = process.env.PUBLIC_URL + result.front;
    //     const back = process.env.PUBLIC_URL + result.back;

    //     fabric.Image.fromURL(front, (bg) => {
    //         const frontCanvas = getCanvasBySide(
    //             this.props.allCanvasList,
    //             "front"
    //         );
    //         const width = frontCanvas?.width, height = frontCanvas?.height

    //         const backScaleX = width / bg.width,
    //         backScaleY = height / bg.height,
    //         backScale = (backScaleX > backScaleY ? backScaleX : backScaleY)

    //      console.log('zoomlevel', frontCanvas.getZoom(), frontCanvas.zoomlevel)
    //         frontCanvas.setBackgroundImage(
    //             bg,
    //             frontCanvas.renderAll.bind(frontCanvas),
    //             {
    //                 scaleX: backScale  ,
    //                 scaleY: backScale,
    //             }
    //         );
    //         saveCanvasState(frontCanvas);
    //     });

    //     fabric.Image.fromURL(back, (bg) => {
    //         const backCanvas = getCanvasBySide(
    //             this.props.allCanvasList,
    //             "back"
    //         );
    //         bg.scaleToHeight(backCanvas.height)
    //         bg.scaleToWidth(backCanvas.width)

    //         backCanvas.setBackgroundImage(
    //             bg,
    //             backCanvas.renderAll.bind(backCanvas),
    //             {
    //                 scaleX: backCanvas.width / bg.width,
    //                 scaleY: backCanvas.height / bg.height,
    //             }
    //         );
    //         saveCanvasState(backCanvas);
    //     });
    // };

    grad1colorOpen = () => {
        this.setState({
            displaygrad1ColorPicker: !this.state.displaygrad1ColorPicker,
        });
    };

    grad1colorClose = () => {
        this.setState({
            displaygrad1ColorPicker: false,
        });
        this.dynamicBGcolors(this.state.backgroundColor);
        this.setGradientBGcolor(
            this.state.grad1color,
            this.state.grad2color,
            "vertical"
        );
    };

    grad2colorOpen = () => {
        this.setState({
            displaygrad2ColorPicker: !this.state.displaygrad2ColorPicker,
        });
    };

    grad2colorClose = () => {
        this.setState({
            displaygrad2ColorPicker: false,
        });
        this.setGradientBGcolor(
            this.state.grad1color,
            this.state.grad2color,
            "vertical"
        );
    };

    bgcolorOpen = () => {
        this.setState({
            displaybgColorPicker: !this.state.displaybgColorPicker,
        });
    };

    bgcolorClose = () => {
        this.setState({
            displaybgColorPicker: false,
        });
        this.dynamicBGcolors(this.state.backgroundColor);
    };

    setVerticalgradient = (color) => {
        this.setGradientBGcolor(
            this.state.grad1color,
            this.state.grad2color,
            "vertical"
        );
    };

    setRadialgradient = (color) => {
        this.setGradientBGcolor(
            this.state.grad1color,
            this.state.grad2color,
            "radial"
        );
    };

    setGradient1BGcolor = (color) => {
        this.setState({
            grad1color: color.hex,
        });
    };

    setGradient2BGcolor = (color) => {
        this.setState({
            grad2color: color.hex,
        });
    };
    print = (type) => {
        var allCanvasList = this.props.allCanvasList;

        var pdf = new jsPDF({
            orientation: "p",
            unit: "in",
            format: "A3",
            putOnlyUsedFonts: true,
        });

        const productName = this.props.currentBookmark;
        const productData = productTypes[productName];
        const pageWidth = parseFloat(productData['canvaswidth'])
        const pageHeight = parseFloat(productData['canvasheight'])
        const ups = parseInt(this.props.currentBookmark)
        allCanvasList.map(async (item) => {
            const clonedcanvas = item.canvas;
            const tempBg = clonedcanvas.backgroundImage
            if(type === 'proof'){
                clonedcanvas.setBackgroundImage('#fff', clonedcanvas.renderAll.bind(clonedcanvas));
            } else {
                clonedcanvas.setBackgroundImage(null, clonedcanvas.renderAll.bind(clonedcanvas));
            }
            if (clonedcanvas) {
                var imgData = clonedcanvas?.toDataURL({ format: "png", enableRetinaScaling: true, quality: 1 });
                var imageWidth = pageWidth;
                var imageHeight = pageHeight;
                //var ratio = imageWidth/imageHeight >= pageWidth/pageHeight ? pageWidth/imageWidth : pageHeight/imageHeight;
                let left = 0.2
                let top = 0.2
                for(let i = 0; i< ups;i++){
                    pdf.addImage(imgData, "PNG", left, top, imageWidth, imageHeight);
                    left= left+imageWidth+0.2
                    if(i === 3) {
                        top = imageHeight + 0.4
                        left = 0.2
                    }
                }
                pdf.addPage();
                clonedcanvas.setBackgroundImage(tempBg, clonedcanvas.renderAll.bind(clonedcanvas));
            }
        });
        var pageCount = pdf.internal.getNumberOfPages();
        pdf.deletePage(pageCount);
        let filename;
        if(type === 'proof'){
            filename = `print-proof-${this.props.currentBookmark}.pdf`;
        } else {
            filename = `print-${this.props.currentBookmark}.pdf`;
        }
        pdf.save(filename);

        // html2canvas(document.querySelector('#front'), {useCORS: true}).then(function(canvas) {
        //     let img = new Image();
        //     img.src = canvas.toDataURL('image/png');
        //     img.onload = function () {
        //       let pdf = new jsPDF('landscape', 'mm', 'a4');
        //       pdf.addImage(img, 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
        //       pdf.save('certificate.pdf');
        //     };
        //   });
        // html2canvas(document.querySelector("#front")).then(canvas => {

        //     var imgData = canvas.toDataURL("image/jpeg",1);
        //     var pdf = new jsPDF("p", "mm", "a4");
        //     var pageWidth = pdf.internal.pageSize.getWidth();
        //     var pageHeight = pdf.internal.pageSize.getHeight();
        //     var imageWidth = canvas.width;
        //     var imageHeight = canvas.height;

        //     var ratio = imageWidth/imageHeight >= pageWidth/pageHeight ? pageWidth/imageWidth : pageHeight/imageHeight;
        //     pdf.addImage(imgData, 'JPEG', 0, 0, imageWidth * ratio, imageHeight * ratio);
        //     pdf.save("invoice.pdf");

        // });
        // html2canvas(document.querySelector("#abs1")).then(canvas => {
        //     var imgData = canvas.toDataURL("image/jpeg");
        //     //var pdf = new jsPDF("p", "mm", "a4");
        //     let pdf = new jsPDF('landscape', 'mm', 'a4');
        //     // var pageWidth = pdf.internal.pageSize.getWidth();
        //     // var pageHeight = pdf.internal.pageSize.getHeight();
        //     // var imageWidth = canvas.width;
        //     // var imageHeight = canvas.height;

        //     //var ratio = imageWidth/imageHeight >= pageWidth/pageHeight ? pageWidth/imageWidth : pageHeight/imageHeight;
        //     //pdf.addImage(imgData, 'JPEG', 0, 0, imageWidth * ratio, imageHeight * ratio);
        //     // pdf.addImage(imgData, 'JPEG', 15, 0, 34, 37);
        //     // pdf.save("invoice.pdf");
        //     // pdf.text(20, 20, 'Hello world!');
        //     // pdf.text(20, 30, 'This is client-side Javascript, pumping out a PDF.');
        //     // pdf.addPage();
        //     // pdf.text(20, 20, 'Do you like that?');
        //     pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);

        //     pdf.save("invoice.pdf");
        // });

        //         var doc = new jsPDF('p', 'pt','a4',true);
        // doc.setFontSize(16);
        // doc.setTextColor(80, 77, 78);
        // doc.text(15, 2, 'should be an image under here');
        // html2canvas($(".upper-canvas"), {
        //     useCORS : true,
        //     onrendered: function(canvas) {
        //        var imgData = canvas.toDataURL('image/jpeg');

        //        doc.addImage(imgData, 'JPEG', 15, 0, 34, 37);
        //        console.log(imgData);
        //       // $('#temp').append(canvas);
        //        doc.save('Spec_Sheet.pdf');
        //     }
        // });

        // // var imgData = canvas.toDataURL(
        // //     'image/png');

        // // doc.addImage(imgData, 'PNG', 10, 10);
        // // doc.save('sample-file.pdf');

        //     const imgData = canvas.toDataURL("image/jpeg", 1.0);
        //     pdf.addImage(imgData, 'JPEG', 0, 0);
        //     pdf.save("download.pdf");
        // const holder=document.querySelector("#temp")
        // // html2canvas(dom, {
        // //     onrendered: function(canvas) {
        // //         var imgData = canvas.toDataURL(
        // //             'image/png');
        // //         var doc = new jsPDF('p', 'mm');
        // //         imgData.crossOrigin = "Anonymous";
        // //         doc.addImage(imgData, 'PNG', 10, 10);
        // //         doc.save('sample-file.pdf');
        // //     }
        // // });
        // html2canvas(holder,{allowTaint:true},{ onrendered: function(canvas) {
        //     var imgData = canvas.toDataURL('image/png');
        //     var doc = new jsPDF('p', 'mm');
        //     doc.addImage(imgData, 'JPEG', 10, 10);
        //     doc.save('sample-file.pdf');
        //     }
        //   });
        //         var pdf = new jsPDF('l', 'pt', 'a4');
        //  var options = {
        //     pagesplit: true
        // };

        // pdf.addHTML(holder, 0, 0, options, function(){
        //     pdf.save("test.pdf");
        // });
        // html2canvas(holder,{allowTaint:true}).then(canvas => {
        //     var imgData = canvas.toDataURL('image/png');
        //         var doc = new jsPDF('p', 'mm');
        //         doc.addImage(imgData, 'PNG', 10, 10);
        //         doc.save('sample-file.pdf');
        // });
    };
    setGradientBGcolor = (colone, coltwo, type) => {
        var canvas = this.props.canvas;
        var canvasObj = this.props.canvasObj;
        if (
            canvasObj[2] === undefined ||
            canvasObj[canvasObj.length - 1] === "front"
        )
            canvas = canvasObj[0];
        else canvas = canvasObj[1];
        if (!colone || !coltwo) return;
        deleteCanvasBg(this.props.canvas);

        if (type === "vertical") {
            var verticalgrad = new fabric.Gradient({
                type: "linear",
                coords: {
                    x1: 0,
                    y1: canvas.height / 4,
                    x2: 0,
                    y2: canvas.height / 2 + canvas.height / 4,
                },
                colorStops: [
                    {
                        color: colone,
                        offset: 0,
                    },
                    {
                        color: coltwo,
                        offset: 1,
                    },
                ],
            });
            canvas.backgroundColor = verticalgrad;
            canvas.renderAll();
        }
        if (type === "radial") {
            var radialgrad = new fabric.Gradient({
                type: "radial",
                coords: {
                    r1: canvas.width / 2,
                    r2: canvas.width / 4,
                    x1: canvas.width / 2 - 1,
                    y1: canvas.height / 2 - 1,
                    x2: canvas.width / 2,
                    y2: canvas.height / 2,
                },
                colorStops: [
                    {
                        color: colone,
                        offset: 0,
                    },
                    {
                        color: coltwo,
                        offset: 1,
                    },
                ],
            });
            canvas.backgroundColor = radialgrad;
            canvas.renderAll();
        }
        if (type === "horizontal") {
            var horizontalgrad = new fabric.Gradient({
                type: "linear",
                coords: {
                    x1: canvas.width / 4,
                    y1: 0,
                    x2: canvas.width / 2 + canvas.width / 4,
                    y2: 0,
                },
                colorStops: [
                    {
                        color: colone,
                        offset: 0,
                    },
                    {
                        color: coltwo,
                        offset: 1,
                    },
                ],
            });
            canvas.backgroundColor = horizontalgrad;
            canvas.renderAll();
        }
        saveCanvasState(canvas);
    };

    setBGcolor = (color) => {
        deleteCanvasBg(this.props.canvas);
        var canvas = this.props.canvas;
        if (canvas) {
            // var canvasObj = this.props.canvasObj;
            // if(canvasObj[2]==undefined || canvasObj[canvasObj.length - 1] === 'front')
            // canvas=canvasObj[0]
            // else
            // canvas=canvasObj[1]
            canvas.backgroundColor = color;
            canvas.renderAll();
            this.setState({
                backgroundColor: color,
            });
            saveCanvasState(canvas);
        }
    };

    refreshCanvas = (canvas) => {
        canvas.renderAll(canvas);
        saveCanvasState(canvas);
    };

    applyBGPattern = (result) => {
        deleteCanvasBg(this.props.canvas);
        var canvas = this.props.canvas;
        canvas.setBackgroundColor(
            {
                source: result,
            },
            this.refreshCanvas.bind(this, canvas)
        );
    };

    incermentpage = () => {
        this.setState(
            {
                pagenum: this.state.pagenum + 1,
            },
            () => {}
        );
    };

    addImage = (result) => {
        this.closeModal();
        var canvas = this.props.canvas;
        fabric.Image.fromURL(result, function (img) {
            img.set({
                id: Math.floor(Math.random() * 1000),
                left: 100,
                top: 100,
                width: img.width,
                height: img.height,
                strokeWidth: "",
                strokeLineJoin: "bevil",
                fill: "#6c757d",
                subTargetCheck: true,
                transparentCorners: false,
                cornerColor: "#1babeb",
                cornerStrokeColor: "#1babeb",
                borderColor: "#1babeb",
                cornerSize: 10,
                padding: 5,
                cornerStyle: "circle",
            });
            if(canvas){
                canvas.add(img);
                canvas.setActiveObject(img);
                canvas.centerObject(img);
                canvas.renderAll();

            }
        });
    };

    searchImage = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            this.setState(
                {
                    searchkey: e.target.value,
                },
                () => {}
            );
        }
    };

    incerment = () => {
        this.setState(
            {
                page: this.state.page + 1,
            },
            () => {}
        );
    };

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    imagetoggle(tab) {
        if (this.state.imgactiveTab !== tab) {
            this.setState({
                imgactiveTab: tab,
            });
        }
    }

    setTemplateKey(templateKey) {
        this.props.setTemplateKey(templateKey)
    }

    setActiveTab(activeTabKey){
        this.props.setActiveTab(activeTabKey)
    }
    render() {
        const { clipartData, subcat, clipartImages } = this.state;
        const templateKey = this.props.templateKey
        const activeTab=this.props.activeTab
        return (
            <>

        
             <div className="side-panel-container largedevice">
                    <TabPanel tabId="vertical-tab-one">
                        <Container className="text-editer">
                            <Row className="mb-2">
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                    }}
                                >
                                    Click on Text to add on canvas
                                </Col>
                            </Row>
                            <Row className="mb-2 white-bg grey-border">
                                <Col align="left">
                                    <h1
                                        className="big-title pt-2"
                                        onClick={() => {
                                            this.addText(
                                                "heading",
                                                "Add Heading"
                                            );
                                        }}
                                    >
                                        Add heading
                                    </h1>
                                </Col>
                            </Row>
                            <Row className="mb-2 white-bg grey-border">
                                <Col align="left mb-2">
                                    <h3
                                        className="sub-title pt-2"
                                        onClick={() => {
                                            this.addText("title", "Add Title");
                                        }}
                                    >
                                        Add title
                                    </h3>
                                </Col>
                            </Row>
                            <Row className="mb-2 white-bg grey-border">
                                <Col align="left">
                                    <h5
                                        className="text pt-2"
                                        onClick={() => {
                                            this.addText(
                                                "text",
                                                "Add Description"
                                            );
                                        }}
                                    >
                                        Add description
                                    </h5>
                                </Col>
                            </Row>
                        </Container>
                    </TabPanel>
                    <TabPanel tabId="vertical-tab-two">
                        <Container>
                            <Row>
                                <Col
                                    sm="12"
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                        paddingLeft: "20%",
                                        backgroundColor: "#e5f6fd",
                                    }}
                                >
                                    Add Background Color
                                </Col>
                            </Row>
                            <Row style={{ backgroundColor: "white" }}>
                                <Col>
                                    <p className="first-title">Solid Colors</p>
                                    <div className="solid-colors">
                                        {
                                            
                                        INIT_SOLID_COLORS?.map((item) => (
                                            <span
                                                key={item}
                                                className="solidcolor"
                                                style={{
                                                    backgroundColor: item,
                                                }}
                                                onClick={() =>
                                                    this.setBGcolor(item)
                                                }
                                            />
                                        ))}
                                        

                                        {this.state.bgcolArray?.map(
                                            (colorval, index) => {
                                                return colorval ? (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            background:
                                                                colorval,
                                                        }}
                                                        className="solidcolor"
                                                        onClick={() =>
                                                            this.setBGcolor(
                                                                colorval
                                                            )
                                                        }
                                                    ></span>
                                                ) : null;
                                            }
                                        )}

                                        <span
                                            className="solidcolor"
                                            onClick={this.bgcolorOpen}
                                        >
                                            <span className="addcolor">+</span>
                                        </span>

                                        {this.state.displaybgColorPicker ? (
                                            <div className="popover">
                                                <div
                                                    className="cover"
                                                    onClick={this.bgcolorClose}
                                                />
                                                <SketchPicker
                                                    color={
                                                        this.state
                                                            .backgroundColor
                                                    }
                                                    onChangeComplete={
                                                        this.setCanvasFill
                                                    }
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{ backgroundColor: "white" }}>
                                <Col>
                                    <p className="first-title">Gradients</p>
                                    <div className="gradients-colors">
                                        <span
                                            className="grdcol1 grdcolor"
                                            onClick={() =>
                                                this.setGradientBGcolor(
                                                    "#62ff00",
                                                    "yellow",
                                                    "vertical"
                                                )
                                            }
                                        />
                                        <span
                                            className="grdcol2 grdcolor"
                                            onClick={() =>
                                                this.setGradientBGcolor(
                                                    "red",
                                                    "yellow",
                                                    "horizontal"
                                                )
                                            }
                                        />
                                        <span
                                            className="grdcol3 grdcolor"
                                            onClick={() =>
                                                this.setGradientBGcolor(
                                                    "#ff9900",
                                                    "#39d4cd",
                                                    "horizontal"
                                                )
                                            }
                                        />
                                        <span
                                            className="grdcol4 grdcolor"
                                            onClick={() =>
                                                this.setGradientBGcolor(
                                                    "rgba(255,0,0,0)",
                                                    "rgba(255,0,0,1)",
                                                    "horizontal"
                                                )
                                            }
                                        />
                                        <span
                                            className="grdcol4 grdcolor"
                                            onClick={() =>
                                                this.setGradientBGcolor(
                                                    "rgba(255,0,0,0)",
                                                    "rgba(255,0,0,1)",
                                                    "horizontal"
                                                )
                                            }
                                        />

                                        {/* <Popup
                                        trigger={<span className="grdcolor"><span className="addcolor">+</span></span>}
                                        position="top left"
                                        closeOnDocumentClick
                                    >
                                        <div className="gradcolorsection">
                                            <div className="grdsection">
                                                <div className="swatch" onClick={this.grad1colorOpen}>
                                                    <div className="grad-color" style={styles.grad1color} />
                                                </div>
                                                {this.state.displaygrad1ColorPicker
                                                    ? <div className="popover">
                                                        <div className="cover" onClick={this.grad1colorClose} />
                                                        <SketchPicker color={this.state.grad1color} onChangeComplete={this.setGradient1BGcolor} />
                                                    </div>
                                                    : null
                                                }
                                                <div className="swatch" onClick={this.grad2colorOpen}>
                                                    <div className="grad-color" style={styles.grad2color} />
                                                </div>
                                                {this.state.displaygrad2ColorPicker
                                                    ? <div className="popover">
                                                        <div className="cover" onClick={this.grad2colorClose} />
                                                        <SketchPicker color={this.state.grad2color} onChangeComplete={this.setGradient2BGcolor} />
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                            <div className="grdsection">
                                                <div className="swatch" onClick={this.setVerticalgradient}>
                                                    <div className="grad-color verticalgradient" title="Vertical" />
                                                </div>
                                                <div className="swatch" onClick={this.setRadialgradient}>
                                                    <div className="grad-color radialgradient" title="Radial" />
                                                </div>
                                            </div>
                                        </div>
                                    </Popup> */}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </TabPanel>
                    <TabPanel tabId="vertical-tab-three"
                        style={{ paddingTop: "30px", paddingLeft: "20px" }}
                    >
                        <Container className="text-editer">
                            <Row>
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                    }}
                                >
                                    Clip&nbsp;Arts
                                </Col>
                            </Row>

                            <TabContent activeTab={this.state.imgactiveTab}>
                                <TabPane tabId="1">
                                    <Row
                                        style={{
                                            backgroundColor: "white",
                                            height: "400px",
                                        }}
                                    >
                                        <Col sm="12">
                                            <p className="first-title">
                                                &nbsp;
                                            </p>
                                            <div className="dcl-upload-button">
                                                <button
                                                    id="showClipartModelBtn"
                                                    className="dcl-button dcl-button--upload dcl-button--full-width"
                                                    onClick={this.openModal}
                                                >
                                                    Browse Clipart Library
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="12">
                                            <div className="pixabaysection">
                                                <Form className="searchbar">
                                                    <Input
                                                        type="text"
                                                        onKeyPress={(event) =>
                                                            this.searchUnsplashimg(
                                                                event
                                                            )
                                                        }
                                                        placeholder="Search Images"
                                                    />
                                                </Form>
                                                <div
                                                    ref={this.imgScrollRef}
                                                    className="scroller"
                                                    id="scroll-1"
                                                >
                                                    {this.state.unsplashImg?.map(
                                                        (photo, index) => {
                                                            return (
                                                                <span
                                                                    className="image-wrapper"
                                                                    key={index}
                                                                    onClick={() =>
                                                                        this.addImage(
                                                                            photo
                                                                                .urls
                                                                                .regular
                                                                        )
                                                                    }
                                                                >
                                                                    <img
                                                                        className="pixabay"
                                                                        src={
                                                                            photo
                                                                                .urls
                                                                                .regular
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </span>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Container>
                    </TabPanel>
                    <TabPanel tabId="vertical-tab-five">
                        <Container className="text-editer">
                            {/* <Nav tabs> */}
                            <Row>
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                    }}
                                >
                                    Image Gallery
                                </Col>
                            </Row>
                            <TabContent activeTab={this.state.imgactiveTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col
                                            sm="12"
                                            style={{ backgroundColor: "#fff" }}
                                        >
                                            <p className="first-title">
                                                &nbsp;
                                            </p>
                                            <div className="dcl-upload-button">
                                                {" "}
                                                <label
                                                    htmlFor="gallery-upload"
                                                    className=" dcl-button dcl-button--upload dcl-button--full-width"
                                                >
                                                    Upload Image
                                                </label>
                                                <input
                                                    id="gallery-upload"
                                                    type="file"
                                                    onChange={this.shapeUpload}
                                                    multiple
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            sm="12"
                                            style={{ backgroundColor: "#fff" }}
                                        >
                                            <div id="uploadedImgDiv">

                                                    {/* <div className="grid">
                                                        {JSON.stringify(this.props.imggallery)}
                                                        {   this.props.imggallery &&
                                                            this.props.imggallery.map( (message, index) => (
                                                             <div className="grid-item" key={index}>
                                                                { JSON.stringify(message)}
                                                             </div>
                                                            ))
                                                        }
                                                    </div> */}

                                                    {<div className="grid">
                                                        {
                                                            this.props.imggallery.map( (message, index) => (
                                                                    <div
                                                                        className="grid-item"
                                                                        key={index}
                                                                    >
                                                                        <img
                                                                            className="image"
                                                                            src={`${HOST_SITE}/${message.imageUrl}`}
                                                                            onClick={() => this.addImage(`${HOST_SITE}/${message.imageUrl}`)}
                                                                            alt=""
                                                                        />
                                                                        <span class="imtip">
                                                                        <i className="fas fa-trash"
                                                                        style={{color:'#6C757D'}}
                                                                        onClick={() => this.removeImg(message.id)}
                                                                        ></i></span>
                                                                    </div>
                                                                )
                                                            )}
                                                    </div>}


                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                            <Modal
                                show={this.state.isDPIOpen || this.state.isLargeImage}
                                onHide={this.closeDPIModal}
                                size="default"
                                id="lowDPI"
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header>
                                    <Modal.Title>
                                        { this.state.isDPIOpen ?
                                            <span style={{ fontSize: "24px" }}>
                                                Low DPI!
                                            </span>: ''}
                                        {this.state.isLargeImage ?
                                            <span style={{ fontSize: "24px" }}>
                                                Large Image
                                            </span> : ''
                                            }

                                    </Modal.Title>
                                    <div
                                        className=""
                                        style={{ position: "absolute", marginLeft: "60%" }}
                                    >
                                        &nbsp;
                                    </div>{" "}
                                        <span
                                            title="close"
                                            style={{ cursor: "pointer" }}
                                            onClick={this.closeDPIModal}
                                        > X</span>
                                </Modal.Header>
                                <Modal.Body>
                                    { this.state.isDPIOpen ?
                                    <p>
                                        <span>
                                            DPI of an uploaded image is <strong>{this.state.imageDPI}</strong>
                                        </span>
                                        <span className="text-danger d-inline-block mt-2">
                                            <strong>Warning:</strong> It is recommended to upload an image with minimum <strong>{DPI_THRESHOLD}</strong> DPI
                                        </span>
                                    </p>
                                    : ''}
                                    { this.state.isLargeImage ?
                                    <p>
                                        <span>
                                            Width of uploaded image is <strong>{this.state.uploadImageWidth}</strong> pixels
                                        </span>
                                        <span className="text-danger d-inline-block mt-2">
                                            <strong>Error:</strong> Image width must be less than <strong>{IMAGE_MAX_WIDTH}</strong> pixels
                                        </span>
                                    </p>
                                    : ''}
                                </Modal.Body>
                                <Modal.Footer style={{ justifyContent: "center" }}>
                                    <button
                                        type="button"
                                        id="closeDPI"
                                        className={`btn btn-primary`}
                                        onClick={this.closeDPIModal}
                                    >
                                    Ok
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </Container>
                    </TabPanel>
                    <TabPanel tabId="vertical-tab-six">
                        <Container className="text-editer">
                            {/* <Nav tabs> */}

                            <Row>
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                    }}
                                >
                                    Template Backgrounds
                                </Col>
                            </Row>
                            <TabContent activeTab={this.state.imgactiveTab}>
                                <TabPane tabId="1">
                                    {/* <Row>
                                    <Col sm="12" style={{backgroundColor:"#fff"}}>
                                        <p className='first-title'>&nbsp;</p>
                                        <div className="dcl-upload-button"> <label htmlFor="template-upload" className=" dcl-button dcl-button--upload dcl-button--full-width">
                                            Select Template
                                        </label>
                                            <input id="template-upload" type="file" onChange={this.templateUpload} />
                                        </div>

                                    </Col>
                                </Row> */}
                                    <Row>
                                        <Col
                                            className="col-sm-12 p-0 m-0"
                                            style={{ backgroundColor: "#fff" }}
                                        >
                                            <div id="uploadedImgDiv">

                                                {this.state.templatesValues &&

                                                    this.state.templatesValues.map( (templateObj,index) => {
                                                            const parentRowClass = 'row col-12 col-offset-1 mt-2 d-flex justify-content-center m-0 p-0'
                                                            const childRowClass = 'row'
                                                            const templateContainer = `col-5 border mt-2 pt-2 mr-1 rounded template-hover `
                                                            const templateTitleRow = 'row col-12 d-flex justify-content-center text-capitalize m-0 p-0 small-font-size align-items-end template-title'
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={parentRowClass}
                                                                >
                                                                    <div
                                                                        className={`${templateContainer} ${templateKey == templateObj[0].title ? 'template-active' : ''}`}
                                                                        onClick={ () => {
                                                                            
                                                                            setcanvasBG(templateObj[0],this.props.allCanvasList)
                                                                            this.setTemplateKey(templateObj[0].title)
                                                                        } }
                                                                    >
                                                                        <div className={childRowClass}>
                                                                            <div className="col-6">
                                                                                <div
                                                                                    className=""
                                                                                    style={{ display:"inline-flex", flexFlow:"wrap" }}
                                                                                    key={index}
                                                                                >
                                                                                    <img
                                                                                        src={templateObj[0].front}
                                                                                        className={`template-thumbnail`}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div
                                                                                    className=""
                                                                                    style={{display:"inline-flex",flexFlow:"wrap",}}
                                                                                    key={index}
                                                                                >
                                                                                    <img
                                                                                        src={templateObj[0].back}
                                                                                        className={`template-thumbnail`}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={templateTitleRow}>
                                                                            {templateObj[0].title}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={`${templateContainer} ${templateKey == templateObj[1].title ? 'template-active' : ''}`}
                                                                        onClick={ () => {

                                                                            setcanvasBG(templateObj[1],this.props.allCanvasList)
                                                                            this.setTemplateKey(templateObj[1].title)
                                                                        } }
                                                                        >
                                                                        <div className={childRowClass}>
                                                                            <div className="col-6">
                                                                                <div
                                                                                    className=""
                                                                                    style={{ display:"inline-flex", flexFlow:"wrap" }}
                                                                                    key={index}
                                                                                >
                                                                                    <img
                                                                                        src={templateObj[1].front}
                                                                                        className={`template-thumbnail`}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div
                                                                                    className=""
                                                                                    style={{display:"inline-flex",flexFlow:"wrap",}}
                                                                                    key={index}
                                                                                >
                                                                                    <img
                                                                                        src={templateObj[1].back}
                                                                                        className={`template-thumbnail`}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={templateTitleRow}>
                                                                            {templateObj[1].title}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )

                                                        }
                                                    )
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Container>
                    </TabPanel>
                    <TabPanel tabId="vertical-tab-seven">
                        <Container className="text-editer">
                            {/* <Nav tabs> */}

                            <Row>
                                <Col
                                    sm="12"
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                        paddingLeft: "30%",
                                    }}
                                >
                                    Print & Download {this.state.imgactiveTab}
                                </Col>
                            </Row>
                            <TabContent activeTab={this.state.imgactiveTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col
                                            sm="12"
                                            style={{ backgroundColor: "#fff" }}
                                        >
                                            <p className="first-title">
                                                &nbsp;
                                            </p>
                                            <button
                                                type="button"
                                                id="printBtn"
                                                className="btn btn-primary btn-search text"
                                                onClick={() => this.print('final')}
                                            >
                                                <i className="fa fa-print"></i>
                                                &nbsp;&nbsp;Print/Download
                                            </button>
                                            <p className="first-title">
                                                &nbsp;
                                            </p>
                                        </Col>
                                        <Col
                                            sm="12"
                                            style={{ backgroundColor: "#fff" }}
                                        >
                                            <button
                                                type="button"
                                                id="printBtn"
                                                className="btn btn-primary btn-search text"
                                                onClick={() => this.print('proof')}
                                            >
                                                <i className="fa fa-print"></i>
                                                &nbsp;&nbsp;Print Proof
                                            </button>
                                            <p className="first-title">
                                                &nbsp;
                                            </p>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Container>
                    </TabPanel>
                    <TabPanel tabId="vertical-tab-eight">
                        <Container className="text-editer">
                            <Row className="mb-2">
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                    }}
                                >
                                    Click on Verse to add on canvas
                                </Col>
                            </Row>
                            <Row className="mb-2 white-bg ">
                                <Col align="left" className="p-0" style={{position:'relative'}}>
                                    <ListGroup variant="flush">
                                    {VERSES.map((verse, index) => (
                                            <OverlayTrigger
                                                key={index}
                                                // trigger={['hover','focus']}
                                                show={this.state.showPopover[index]}
                                                placement="right"
                                                value={index}
                                                popperConfig={{
                                                    index:index
                                                }}

                                                overlay={
                                                <Popover
                                                    id="popover-basic"
                                                    onMouseEnter={(popper) => {this.handleOnMouseEnter(index)}}
                                                    onMouseLeave={(popper) => {this.handleOnMouseLeave(index)}}

                                                >
                                                    <Popover.Header as="h3">{verse.title}</Popover.Header>
                                                    <Popover.Body>
                                                        <p style={{
                                                            whiteSpace: "pre-line",
                                                            textAlign:'center',
                                                            overflowY: 'auto',
                                                            maxHeight:'250px'
                                                        }}>{verse.data}</p>
                                                        <a href="#" class="link-primary" onClick={() => {
                                                        this.addVerse(
                                                            verse.title,
                                                            verse.data
                                                        );
                                                    }}>Add on Canvas</a>
                                                    </Popover.Body>
                                                </Popover>
                                                }
                                            >
                                            <button
                                                className={`button-as-li`}
                                                variant="secondary"
                                                value={index}
                                                onMouseEnter={(popper) => {this.handleOnMouseEnter(index)}}
                                                onMouseLeave={(popper) => {this.handleOnMouseLeave(index)}}
                                                onClick={() => {
                                                    this.addVerse(
                                                        verse.title,
                                                        verse.data
                                                    );
                                                }}
                                            >{verse.title}</button>
                                            </OverlayTrigger>
                                        ))}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Container>
                    </TabPanel>
                    <TabPanel tabId="vertical-tab-four"
                        style={{ height: "500px" }}
                    >
                        <Container className="text-editer">
                            <Row>
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                    }}
                                >
                                    Shapes
                                </Col>
                            </Row>

                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col
                                            sm="12"
                                            style={{
                                                backgroundColor: "white",
                                                height: "400px",
                                            }}
                                        >
                                            {/* <p className='first-title'>Shapes</p> */}
                                            <div className="patterns shapes">
                                                <i
                                                    className="fas fa-circle "
                                                    style={{
                                                        transform:
                                                            "rotate(270deg)",
                                                        fontSize: "4em",
                                                        color: "#000",
                                                        marginLeft: "0px",
                                                        cursor: "pointer",
                                                        height: "70px",
                                                        width: "70px",
                                                    }}
                                                    onClick={() =>
                                                        this.fillImage(1)
                                                    }
                                                ></i>
                                                <i
                                                    className="fas fa-square "
                                                    style={{
                                                        transform:
                                                            "rotate(270deg)",
                                                        fontSize: "4em",
                                                        color: "#000",
                                                        marginLeft: "15px",
                                                        cursor: "pointer",
                                                        height: "70px",
                                                        width: "70px",
                                                    }}
                                                    onClick={() =>
                                                        this.fillImage(2)
                                                    }
                                                ></i>
                                                <i
                                                    className="fas fa-star "
                                                    style={{
                                                        transform:
                                                            "rotate(270deg)",
                                                        fontSize: "4em",
                                                        marginTop: "6px",
                                                        color: "#000",
                                                        marginLeft: "14px",
                                                        cursor: "pointer",
                                                        height: "70px",
                                                        width: "70px",
                                                    }}
                                                    onClick={() =>
                                                        this.fillImage(3)
                                                    }
                                                ></i>
                                                {/* <i className="fa fa-diamond" style={{ transform: 'rotate(0deg)', fontSize: '4em', color: '#000', marginLeft: '0px', marginTop: '6px', cursor: 'pointer', height: "70px", width: "75px" }} onClick={() => this.fillImage(4)}></i> */}
                                                <img
                                                    src={diamond}
                                                    onClick={() =>
                                                        this.fillImage(4)
                                                    }
                                                    style={{
                                                        cursor: "pointer",
                                                        height: "70px",
                                                        width: "75px",
                                                    }}
                                                    alt=""
                                                />
                                                <i
                                                    className="bi bi-hexagon-fill"
                                                    style={{
                                                        transform:
                                                            "rotate(270deg)",
                                                        fontSize: "4em",
                                                        color: "#000",
                                                        marginLeft: "10px",
                                                        marginTop: "-10px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        this.fillImage(5)
                                                    }
                                                ></i>
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Container>
                    </TabPanel>
                </div>
                <div className="mobilenav" style={{width:"100%"}}>

                <TabContent activeTab={activeTab} style={{backgroundColor:"#F1F1F1",border:"0.5px solid grey"}}>

          <TabPane tabId="1">
         
                        <Container className="text-editer" style={{backgroundColor:"#DCF0F7"}}>
                            <Row className="mb-2">
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                        backgroundColor:"#dcf0f7",
                                    }}
                                >
                                    Click on Text to add on canvas
                                </Col>
                            </Row>
                            <Row className="mb-2 white-bg grey-border">
                                <Col align="left">
                                    <h1
                                        className="big-title pt-2"
                                        onClick={() => {
                                            this.addText(
                                                "heading",
                                                "Add Heading"
                                            );
                                            this.setActiveTab("")

                                        }}
                                        style={{border:"1px solid grey",borderRadius:"5px",backgroundColor:"white"}}

                                    >
                                        Add heading
                                    </h1>
                                </Col>
                            </Row>
                            <Row className="mb-2 white-bg grey-border">
                                <Col align="left mb-2">
                                    <h3
                                        className="sub-title pt-2"
                                        onClick={() => {
                                            this.addText("title", "Add Title");
                                            this.setActiveTab("")

                                        }}
                                        style={{border:"1px solid grey",borderRadius:"5px",backgroundColor:"white"}}
                                    >
                                        Add title
                                    </h3>
                                </Col>
                            </Row>
                            <Row className="white-bg grey-border">
                                <Col align="left">
                                    <h5
                                        className="text pt-2"
                                        style={{border:"1px solid grey",borderRadius:"5px",backgroundColor:"white"}}

                                        onClick={() => {
                                            this.addText(
                                                "text",
                                                "Add Description"
                                            );
                                            this.setActiveTab("")

                                        }}
                                    >
                                        Add description
                                    </h5>
                                </Col>
                            </Row>
                        </Container>
                
          </TabPane>
          <TabPane tabId="2">
                        <Container className="text-editer">
                            {/* <Nav tabs> */}

                            <Row>
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center `}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                        backgroundColor:"#dcf0f7",
                                    }}
                                >
                                    Template Backgrounds
                                </Col>
                            </Row>
                            <TabContent activeTab={this.state.imgactiveTab}>
                                <TabPane tabId="1">
                                    {/* <Row>
                                    <Col sm="12" style={{backgroundColor:"#fff"}}>
                                        <p className='first-title'>&nbsp;</p>
                                        <div className="dcl-upload-button"> <label htmlFor="template-upload" className=" dcl-button dcl-button--upload dcl-button--full-width">
                                            Select Template
                                        </label>
                                            <input id="template-upload" type="file" onChange={this.templateUpload} />
                                        </div>

                                    </Col>
                                </Row> */}
                                    <Row>
                                        <Col
                                            className="col-sm-12 p-0 m-0"
                                            style={{ backgroundColor: "#fff"}}
                                        >
                                            <div id="uploadedImgDiv" style={{maxHeight:"300px"}}>

                                                {this.state.templatesValues &&

                                                    this.state.templatesValues.map( (templateObj,index) => {
                                                            const parentRowClass = 'row col-12 col-offset-1 mt-2 d-flex justify-content-center m-0 p-0'
                                                            const childRowClass = 'row'
                                                            const templateContainer = `col-5 border mt-2 pt-2 mr-1 rounded template-hover `
                                                            const templateTitleRow = 'row col-12 d-flex justify-content-center text-capitalize m-0 p-0 small-font-size align-items-end template-title'
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={parentRowClass}
                                                                >
                                                                    <div
                                                                        className={`${templateContainer} ${this.state.templateKey == templateObj[0].title ? 'template-active' : ''}`}
                                                                        onClick={ () => {
                                                                            setcanvasBG(templateObj[0],this.props.allCanvasList)
                                                                            this.setTemplateKey(templateObj[0].title)
                                                                            this.setActiveTab("")
                                                                        } }
                                                                    >
                                                                        <div className={childRowClass}>
                                                                            <div className="col-6">
                                                                                <div
                                                                                    className=""
                                                                                    style={{ display:"inline-flex", flexFlow:"wrap" }}
                                                                                    key={index}
                                                                                >
                                                                                    <img
                                                                                        src={templateObj[0].front}
                                                                                        className={`template-thumbnail`}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div
                                                                                    className=""
                                                                                    style={{display:"inline-flex",flexFlow:"wrap",}}
                                                                                    key={index}
                                                                                >
                                                                                    <img
                                                                                        src={templateObj[0].back}
                                                                                        className={`template-thumbnail`}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={templateTitleRow}>
                                                                            {templateObj[0].title}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className={`${templateContainer} ${this.props.templateKey == templateObj[1].title ? 'template-active' : ''}`}
                                                                        onClick={ () => {
                                                                            
                                                                            setcanvasBG(templateObj[1],this.props.allCanvasList)
                                                                            this.setTemplateKey(templateObj[1].title)
                                                                            this.setActiveTab("")

                                                                        } }
                                                                        >
                                                                        <div className={childRowClass}>
                                                                            <div className="col-6">
                                                                                <div
                                                                                    className=""
                                                                                    style={{ display:"inline-flex", flexFlow:"wrap" }}
                                                                                    key={index}
                                                                                >
                                                                                    <img
                                                                                        src={templateObj[1].front}
                                                                                        className={`template-thumbnail`}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <div
                                                                                    className=""
                                                                                    style={{display:"inline-flex",flexFlow:"wrap",}}
                                                                                    key={index}
                                                                                >
                                                                                    <img
                                                                                        src={templateObj[1].back}
                                                                                        className={`template-thumbnail`}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={templateTitleRow}>
                                                                            {templateObj[1].title}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )

                                                        }
                                                    )
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Container>
                    
          </TabPane>
          <TabPane tabId="3">
                        <Container className="text-editer">
                            {/* <Nav tabs> */}
                            <Row>
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                        paddingBottom:"5%",
                                        backgroundColor:"#dcf0f7"
                                    }}
                                >
                                    Image Gallery
                                </Col>
                            </Row>
                            <TabContent activeTab={this.state.imgactiveTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col
                                            sm="12"
                                            style={{ backgroundColor: "#fff" }}
                                        >
                                            <p className="first-title">
                                                &nbsp;
                                            </p>
                                            <div className="dcl-upload-button">
                                                {" "}
                                                <label
                                                    htmlFor="gallery-upload"
                                                    className=" dcl-button dcl-button--upload dcl-button--full-width"
                                                >
                                                    Upload Image
                                                </label>
                                                <input
                                                    id="gallery-upload"
                                                    type="file"
                                                    onChange={this.shapeUpload}
                                                    multiple
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            sm="12"
                                            style={{ backgroundColor: "#fff" }}
                                        >
                                            <div id="uploadedImgDiv">

                                                    {/* <div className="grid">
                                                        {JSON.stringify(this.props.imggallery)}
                                                        {   this.props.imggallery &&
                                                            this.props.imggallery.map( (message, index) => (
                                                             <div className="grid-item" key={index}>
                                                                { JSON.stringify(message)}
                                                             </div>
                                                            ))
                                                        }
                                                    </div> */}

                                                    {<div className="grid">
                                                        {
                                                            this.props.imggallery.map( (message, index) => (
                                                                    <div
                                                                        className="grid-item"
                                                                        key={index}
                                                                    >
                                                                        <img
                                                                            className="image"
                                                                            src={`${HOST_SITE}/${message.imageUrl}`}
                                                                            onClick={() => {this.addImage(message.imageUrl)
                                                                                this.setActiveTab("")}

                                                                            }
                                                                            alt=""
                                                                        />
                                                                        <span class="imtip">
                                                                        <i className="fas fa-trash"
                                                                        style={{color:'#6C757D'}}
                                                                        onClick={() => this.removeImg(message.id)}
                                                                        ></i></span>
                                                                    </div>
                                                                )
                                                            )}
                                                    </div>}


                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                            <Modal
                                show={this.state.isDPIOpen || this.state.isLargeImage}
                                onHide={this.closeDPIModal}
                                size="default"
                                id="lowDPI"
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header>
                                    <Modal.Title>
                                        { this.state.isDPIOpen ?
                                            <span style={{ fontSize: "24px" }}>
                                                Low DPI!
                                            </span>: ''}
                                        {this.state.isLargeImage ?
                                            <span style={{ fontSize: "24px" }}>
                                                Large Image
                                            </span> : ''
                                            }

                                    </Modal.Title>
                                    <div
                                        className=""
                                        style={{ position: "absolute", marginLeft: "60%" }}
                                    >
                                        &nbsp;
                                    </div>{" "}
                                        <span
                                            title="close"
                                            style={{ cursor: "pointer" }}
                                            onClick={this.closeDPIModal}
                                        > X</span>
                                </Modal.Header>
                                <Modal.Body>
                                    { this.state.isDPIOpen ?
                                    <p>
                                        <span>
                                            DPI of an uploaded image is <strong>{this.state.imageDPI}</strong>
                                        </span>
                                        <span className="text-danger d-inline-block mt-2">
                                            <strong>Warning:</strong> It is recommended to upload an image with minimum <strong>{DPI_THRESHOLD}</strong> DPI
                                        </span>
                                    </p>
                                    : ''}
                                    { this.state.isLargeImage ?
                                    <p>
                                        <span>
                                            Width of uploaded image is <strong>{this.state.uploadImageWidth}</strong> pixels
                                        </span>
                                        <span className="text-danger d-inline-block mt-2">
                                            <strong>Error:</strong> Image width must be less than <strong>{IMAGE_MAX_WIDTH}</strong> pixels
                                        </span>
                                    </p>
                                    : ''}
                                </Modal.Body>
                                <Modal.Footer style={{ justifyContent: "center" }}>
                                    <button
                                        type="button"
                                        id="closeDPI"
                                        className={`btn btn-primary`}
                                        onClick={this.closeDPIModal}
                                    >
                                    Ok
                                    </button>
                                </Modal.Footer>
                            </Modal>
                        </Container>
                   
          </TabPane>
          <TabPane tabId="4">
                        <Container className="text-editer" >
                            <Row className="mb-2">
                                <Col
                                    sm="12"
                                    className={`d-flex justify-content-center`}
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                        backgroundColor:"#dcf0f7"
                                    }}
                                >
                                    Click on Verse to add on canvas
                                </Col>
                            </Row>
                            <Row className="white-bg ">
                                <Col align="left" className="p-0" style={{position:'relative'}}>
                                    <ListGroup variant="flush" >
                                    {VERSES.map((verse, index) => (
                                            <OverlayTrigger
                                                key={index}
                                                trigger={['hover','focus']}
                                                placement="right"
                                                overlay={<Popover id="popover-basic">
                                                <Popover.Header as="h3">{verse.title}</Popover.Header>
                                                <Popover.Body>
                                                    <p style={{
                                                        whiteSpace: "pre-line",
                                                        textAlign:'center',
                                                        overflowX: 'scroll'
                                                    }}>{verse.data}</p>
                                                </Popover.Body>
                                                </Popover>
                                                }
                                            >
                                            <button
                                                className={`button-as-li`}
                                                style={{backgroundColor:"white",border:"0.5px solid black",marginLeft:"10px",marginRight:"10px",marginTop:"2px",marginBottom:"5px"}}
                                                variant="secondary"
                                                onClick={() => {
                                                    this.addVerse(
                                                        verse.title,
                                                        verse.data
                                                    );
                                                    this.setActiveTab("")

                                                }}
                                            >{verse.title}</button>

                                            </OverlayTrigger>
                                        ))}
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Container>
                    
          </TabPane>
          <TabPane tabId="5">
                        <Container className="text-editer">
                            {/* <Nav tabs> */}

                            <Row>
                                <Col
                                    sm="12"
                                    style={{
                                        height: "50px",
                                        paddingTop: "5%",
                                        paddingLeft: "30%",
                                        backgroundColor:"#dcf0f7"
                                    }}
                                >
                                    Print & Download 
                                </Col>
                            </Row>
                            <TabContent activeTab={this.state.imgactiveTab}>
                                <TabPane tabId="1">
                                    <Row>
                                        <Col
                                            sm="12"
                                            style={{ backgroundColor: "#fff" }}
                                        >
                                            <p className="first-title">
                                                &nbsp;
                                            </p>
                                            <button
                                                type="button"
                                                id="printBtn"
                                                className="btn btn-primary btn-search text"
                                                onClick={() => this.print('final')}
                                            >
                                                <i className="fa fa-print"></i>
                                                &nbsp;&nbsp;Print/Download
                                            </button>
                                            <p className="first-title">
                                                &nbsp;
                                            </p>
                                        </Col>
                                        <Col
                                            sm="12"
                                            style={{ backgroundColor: "#fff" }}
                                        >
                                            <button
                                                type="button"
                                                id="printBtn"
                                                className="btn btn-primary btn-search text"
                                                onClick={() => this.print('proof')}
                                            >
                                                <i className="fa fa-print"></i>
                                                &nbsp;&nbsp;Print Proof
                                            </button>
                                            <p className="first-title">
                                                &nbsp;
                                            </p>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Container>
                    
         </TabPane>

               </TabContent>
                </div>
             
                
                
            
            
                <Modal
                    show={this.state.isOpen}
                    onHide={this.closeModal}
                    size="xl"
                    id="modal-content1"
                >
                    <Modal.Header>
                        <Modal.Title>
                            <span style={{ fontSize: "24px" }}>Clip Art</span>
                        </Modal.Title>
                        <div
                            className=""
                            style={{ position: "relative", marginLeft: "60%" }}
                        >
                            <input
                                type="text"
                                name="search"
                                value=""
                                id="clipartSearchTerms"
                                placeholder="search clipart"
                                className="clipartSearchTerms"
                            />
                            &nbsp;&nbsp;
                            <button
                                type="button"
                                id="search-clipart"
                                className="btn btn-primary btn-search"
                            >
                                <i className="fa fa-search"></i>
                                &nbsp;&nbsp;Search
                            </button>
                        </div>{" "}
                        <span
                            title="close"
                            style={{ cursor: "pointer" }}
                            onClick={this.closeModal}
                        >
                            X
                        </span>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div
                                className="row-md-6 "
                                style={{
                                    border: "1px solid #e1e6eb",
                                    width: "32%",
                                    paddingLeft: "10px",
                                    borderRadius: "5px",
                                    backgroundColor: "#fff",
                                    overflowY: "scroll",
                                    height: "200px",
                                    fontSize: "18px",
                                }}
                            >
                                Categories
                                {clipartData?.categories &&
                                    clipartData.categories?.map(
                                        (obj, offset) => (
                                            <div className="space" key={offset}>
                                                <DropdownButton
                                                    id="category"
                                                    title={obj.type}
                                                >
                                                    {obj.subcategories &&
                                                        obj.subcategories.map(
                                                            (obj2, index) => (
                                                                <div
                                                                    key={index}
                                                                >
                                                                    <Dropdown.Item
                                                                        as="button"
                                                                        id="subcategory"
                                                                        className="category"
                                                                        onClick={() =>
                                                                            this.getClipart(
                                                                                obj.type,
                                                                                obj2.type
                                                                            )
                                                                        }
                                                                    >
                                                                        {
                                                                            obj2.type
                                                                        }
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                </div>
                                                            )
                                                        )}
                                                </DropdownButton>
                                            </div>
                                        )
                                    )}
                            </div>
                            <div
                                className="row-md-6"
                                style={{ width: "68%", paddingLeft: "10px" }}
                            >
                                <div className="parent">                      
                       
         
                                    {subcat !== 1 ? (
                                        <label>Results for {subcat}</label>
                                    ) : (
                                        <label>Results for Angel</label>
                                    )}
                                </div>
                                <div className="angel-row">
                                    {clipartImages?.map((name, offset) => (
                                        <div className="child" key={offset}>
                                            <img
                                                src={name.url}
                                                onClick={() =>
                                                    this.addImage(name.url)
                                                }
                                                alt=""
                                            />
                                        </div>
                                    ))}
                                </div>

                                {/* <ReactPaginate
                                                previousLabel={"prev"}
                                                nextLabel={"next"}
                                                breakLabel={"..."}
                                                breakClassName={"break-me"}
                                                pageCount={this.state.pageCount}
                                                marginPagesDisplayed={2}
                                                pageRangeDisplayed={5}
                                                onPageChange={this.handlePageClick}
                                                containerClassName={"pagination"}
                                                subContainerClassName={"pages pagination"}
                                                activeClassName={"active"}/> */}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {/* {

          this.state.successElement &&
          <div className="alert alert-success" style={{width:'100%'}}>
            <strong>Success!</strong>&nbsp;&nbsp;&nbsp;New Symptoms has been updated.
           <span style={{color:'black'}}>{resultTitle}</span>
          </div>
        } */}
                        {/* {

      this.state.errElement &&
      <div className="alert alert-danger"  style={{width:'100%'}}>
    <strong>Danger!</strong>&nbsp;&nbsp;&nbsp;New symptoms could not be added.
  </div>
      } */}
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default LeftPanel;
